import { MagnifyingGlassIcon, CheckIcon } from "@heroicons/react/24/solid";
import { ChangeEvent, useMemo, useState } from "react";
import { TextWithEllipsisAndTooltip } from "../../TextWithEllipsisAndTooltip";
import Input from "../../form/Input";
import { FilterComponentProps, FilterValue, SelectFilterOption } from "./types";
import { ComponentLoader } from "src/components/loaders/ComponentLoader";

type SelectFilterProps = FilterComponentProps & {
    label: string;
    options: SelectFilterOption[];
    isMulti: boolean;
    search?: 'inMemory' | 'custom';
    onQueryChange?: (query: string) => void;
    isLoading?: boolean;
}

export const SelectFilter = ({ value, label, options, isMulti, search, onQueryChange, isLoading = false, onChange }: SelectFilterProps) => {
    const [query, setQuery] = useState<string>('');
    const selected = useMemo(() => value?.split(',').filter(Boolean) || [], [value]);
    const setSelected = (selected: FilterValue[]) => {
        onChange(selected.filter(Boolean).join(','));
    };

    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
        if (onQueryChange) {
            onQueryChange(e.target.value);
        }
    };
    const onSelection = (selection: FilterValue) => {
        const isSelected = selected.includes(selection as string);
        if (isSelected) {
            setSelected(selected.filter(s => s !== selection));
        }
        else if (isMulti) {
            setSelected([...selected, selection]);
        } else {
            setSelected([selection]);
        }
    };

    const clear = () => {
        setSelected([]);
    };

    const selectAll = () => {
        setSelected(options.map(option => option.value));
    };

    const filteredOptions = useMemo(() => {
        if (search == 'inMemory') {
            return options.filter(option => !query || option.label.toLowerCase().includes(query.toLowerCase()));
        }
        else {
            return options;
        }
    }, [options, query, search]);

    if (!options.length && search != 'custom') return <EmptyState label={label} />;

    return (
        <div className="p-2 flex flex-col gap-2 text-tertiary">
            {
                search && (options.length >= 10 || search === 'custom') && (
                    <Input
                        placeholder={`Search ${label.toLocaleLowerCase()}`}
                        value={query}
                        onInputChange={onInputChange}
                        icon={<MagnifyingGlassIcon width={16} height={16} className="text-slate-400" />}
                        className="w-full"
                        background='bg-slate-100'
                        focus={true}
                        height="h-8"
                    />
                )
            }
            {
                isMulti && (
                    <div className="flex gap-2 text-sm flex-row-reverse">
                        <span onClick={clear} className="cursor-pointer">Clear</span>
                        <span onClick={selectAll} className="cursor-pointer">Select all</span>
                    </div>
                )
            }
            <div className="flex flex-col max-h-[320px] overflow-auto">
                {
                    !isLoading && (
                        <>
                            {
                                selected.length > 0 && (
                                    <div>
                                        <div className="font-semibold my-1">Selected values</div>
                                        {
                                            filteredOptions.filter(f => selected.includes(f.value)).map(option => <FilterOption key={option.value} option={option} onSelect={onSelection} isSelected={true} />)
                                        }
                                        <div className="font-semibold my-1">Other values</div>
                                    </div>
                                )
                            }
                            {
                                filteredOptions.filter(f => !selected.includes(f.value)).map(option => <FilterOption key={option.value} option={option} onSelect={onSelection} isSelected={false} />)
                            }
                        </>
                    )
                }
                {
                    isLoading && <ComponentLoader />
                }
            </div>
        </div>
    );
};

const EmptyState = ({ label }: { label: string }) => (
    <div className="w-60 p-2">
        <div className="mb-2">{label}</div>
        <div className="text-sm mb-2">No options available</div>
    </div>
);

type FilterOptionProps = {
    option: SelectFilterOption;
    onSelect: (value: FilterValue) => void;
    isSelected: boolean;
}

const FilterOption = ({ option, onSelect, isSelected }: FilterOptionProps) => {
    return (
        <div className="flex text-secondary items-center justify-between hover:bg-slate-100 p-2 cursor-pointer" onClick={() => onSelect(option.value)}>
            <div className="flex gap-2 items-center">
                {
                    option.icon && <div>{option.icon}</div>
                }
                <div><TextWithEllipsisAndTooltip maxChars={30} text={option.label} /></div>
            </div>
            {
                isSelected && (
                    <div><CheckIcon width={16} height={16} className="text-primary" /></div>
                )
            }
        </div>
    );
};