import { EqlToken, LezerToken } from "src/features/models/discover/EQL/types";

const lezerTokenToTokenGroupType = (token: LezerToken): EqlTokenGroupType => {
    switch (token) {
        case 'CNAME':
        case 'EXTENDED_CNAME':
        case 'RELATION':
            return 'property';
        case 'COMPARISON_OP':
        case 'HAS':
        case 'NOT':
        case 'AND':
        case 'OR':
        case 'EQUALS':
        case 'IN':
        case 'IS':
        case 'BETWEEN':
            return 'operator';
        case 'STRING_LITERAL':
        case 'RELATIVE_TIME':
        case 'NONNEG_INT':
        case 'SIGNED_NUMBER':
        case 'BOOL':
        case 'NULL':
        case 'STAR':
            return 'value';
        default:
            return null;
    }
};
type EqlTokenGroupType = 'property' | 'operator' | 'value' | null;

export const highlightEql = (tokens: EqlToken[]): string => {
    const groupTypeToColor = {
        property: '#2F86D2',
        operator: '#7B30D0',
        value: '#A44185',
    };
    const highlightedEql = tokens
        .filter(t => {
            const [tStart, tEnd] = t.pos.split('-').map(Number);
            const hasWrappingToken = tokens.some(t2 => {
                const [t2Start, t2End] = t2.pos.split('-').map(Number);
                return t2Start <= tStart && t2End >= tEnd && t.text !== t2.text;
            });
            return !hasWrappingToken;
        })
        .map(t => {
            const groupType = lezerTokenToTokenGroupType(t.type);
            if (!groupType) {
                return t.text;
            }
            else {
                return `<span style="color: ${groupTypeToColor[groupType]}">${t.text}</span>`;
            }
        })
        .join('');
    return highlightedEql;
};