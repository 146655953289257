import { OperationStatus } from './Operation';

const operationStatusNameMap: Map<OperationStatus, string> = new Map([
  [OperationStatus.Pending, 'Pending'],
  [OperationStatus.Running, 'Running'],
  [OperationStatus.Publishing, 'Running'],
  [OperationStatus.Completed, 'Completed'],
  [OperationStatus.Failed, 'Failed'],
  [OperationStatus.Cancelled, 'Cancelled'],
  [OperationStatus.Canceling, 'Canceling']
]);

const OperationStatusLabel = ({ status }: { status: OperationStatus }) => {
  let color = '';
  switch (status) {
    case OperationStatus.Failed:
      color = 'text-red-600';
      break;
    case OperationStatus.Cancelled:
      color = 'text-orange-600';
      break;
    case OperationStatus.Completed:
      color = 'text-text-primary';
      break;
    default:
      color = 'text-slate-400';
  }
  return <span className={color}>{operationStatusNameMap.get(status)}</span>;
};

export default OperationStatusLabel;
