import { useEffect, useRef, useState } from 'react';
import { Filter } from './Types';
import { useClickAway, useKeyPress, useKeyPressEvent } from 'react-use';
import { events, trackEvent } from '../../../../../infrastructure/analytics';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetFacetsQuery } from 'src/services/nodes/nodes';
import { Toggle } from 'src/components/Toggle';
import { notify } from 'src/components/Toaster';
import showSubResouresInDataModelTableSlice, { selectShowSubResourcesInDataModelTable } from 'src/infrastructure/state/slices/showSubResouresInDataModelTableSlice';
import { BackendNodeType } from 'src/services/nodes/types';
import { SUB_RESOURCE_TYPES } from 'src/features/models/discover/INode';
import { mapBackendNodeTypeToLocalNodeType } from 'src/services/nodes/transformers';
import { Tooltip } from 'react-tooltip';
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import Button from 'src/components/button/Button';
import { ButtonTypes } from 'src/components/button/types';
import Input from 'src/components/form/Input';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { isSidepaneOpen } from 'src/components/Sidepane/isSidepaneOpen';
import { isTypingInInput } from 'src/infrastructure/domUtilies';
import { FilterIcon } from 'src/assets/images/icons/DelphiIcons';
import { UpdateDataModelStateProps } from 'src/features/models/discover/types';

interface FilterPanelProps {
  filters: Filter[];
  updateDataModelState: (props: UpdateDataModelStateProps) => void;
}

export const FilterPanel = ({ filters, updateDataModelState }: FilterPanelProps) => {
  const accountId = useSelector(selectActiveAccountId);
  const { data: facets } = useGetFacetsQuery({ accountId });
  const ref = useRef<HTMLDivElement>(null);
  const [filtersInEdit, setFiltersInEdit] = useState<Filter[]>(filters.map(f => ({ ...f })));
  const [activeFilterName, setActiveFilterName] = useState<string>(filtersInEdit[0].name);
  const activeFilter = filtersInEdit.find(f => f.name === activeFilterName);
  const dispatch = useDispatch();
  const showSubResources = useSelector(selectShowSubResourcesInDataModelTable);
  const [showFiltersMenu, setShowFiltersMenu] = useState<boolean>(false);
  const fPressed = useKeyPress('f');

  useEffect(() => {
    setFiltersInEdit(filters.map(f => ({ ...f })));
  }, [filters]);

  const setShowSubResources = (showSubResources: boolean) => {
    if (!showSubResources && isSubResourceFilterApplied()) {
      notify('Sub-resources are included in the current filter. Please remove the filter to apply this setting.', 'error');
    }
    else {
      trackEvent(events.dataModelDisplaySubresourcesToggled, { showSubResources });
      dispatch(showSubResouresInDataModelTableSlice.actions.setShowSubResourcesInDataModelTable(showSubResources));
    }
  };

  useEffect(() => {
    if (!showSubResources && isSubResourceFilterApplied()) {
      dispatch(showSubResouresInDataModelTableSlice.actions.setShowSubResourcesInDataModelTable(true));
    }
  }, [showSubResources, dispatch]);

  const onClose = () => {
    setFiltersInEdit(filters.map(f => ({ ...f })));
    setShowFiltersMenu(false);
  };

  //on "f" pressed
  useEffect(() => {
    if (fPressed[0] && !isTypingInInput() && !isSidepaneOpen()) {
      setShowFiltersMenu(true);
    }
  }, [fPressed]);

  useClickAway(ref, onClose);
  useKeyPressEvent('Escape', onClose);

  const onFilterChange = (value: string | null) => {
    if (activeFilter) {
      setFiltersInEdit(filtersInEdit.map(f => f.name === activeFilter.name ? { ...f, value } : f));
    }
  };

  const onSave = () => {
    updateDataModelState({ filters: [...filtersInEdit] });
    setShowFiltersMenu(false);
  };

  const appliedFilters = filtersInEdit.filter(f => !f.isDisabled && !!f.value);

  return (
    <div className="relative">
      <div className="text-primary flex gap-1 p-1 border border-border rounded items-center cursor-pointer bg-primary-light mt-1" onClick={() => setShowFiltersMenu(!showFiltersMenu)}>
        <FilterIcon width="16" height="16" />
        {
          appliedFilters.length === 0 && 'Add filters'
        }
        {
          appliedFilters.slice(0, 3).map(f => f.name).join(', ')
        }
        {
          appliedFilters.length > 3 && (
            <span>+{appliedFilters.length - 3} more</span>
          )
        }
      </div>
      {
        showFiltersMenu && (
          <div className="absolute top-12 z-20 rounded border border-border bg-slate-50 shadow w-[600px] h-[450px] flex flex-col" ref={ref}>
            <div className="flex flex-1">
              <FilterListPanel activeFilterName={activeFilterName} setActiveFilterName={setActiveFilterName} filters={filtersInEdit} setFiltersInEdit={setFiltersInEdit} />
              <div className="flex-1 break-all">
                {
                  activeFilter?.component && (
                    <activeFilter.component
                      value={activeFilter.value}
                      onChange={onFilterChange}
                      filterOptions={facets}
                      filters={filtersInEdit}
                    />
                  )
                }
              </div>
            </div>
            <div className="flex gap-2 border-t border-border p-2 items-center">
              <div className="text-slate-600">
                Display sub-resources
              </div>
              <Toggle className="w-[40px] h-[20px]" onChange={() => setShowSubResources(!showSubResources)} value={showSubResources} />
              <div>
                <Tooltip anchorSelect="#DisplaySubResourcesHelper">Include both resources and their sub-resources<br />(e.g., columns, dimensions, etc.) for a more detailed view.</Tooltip>
                <InformationCircleIcon width="16" height="16" className="text-slate-400 cursor-help" id="DisplaySubResourcesHelper" />
              </div>
              <Button className="ml-auto w-36 !bg-lilac-50 border border-lilac-100 !text-primary" onClick={onSave} text="Apply" type={ButtonTypes.primary} />
            </div>
          </div>
        )
      }
    </div>
  );
};

const isSubResourceFilterApplied = (): boolean => {
  const searchParams = new URLSearchParams(window.location.search);
  const typeSearchParam = searchParams.get('Type');
  const backendNodeTypes = (typeSearchParam || '').split(',').map(t => t as BackendNodeType);
  const localNodeTypes = backendNodeTypes.map(t => mapBackendNodeTypeToLocalNodeType.get(t));
  const typeFilterIncludesSubResources = localNodeTypes.some(t => t && SUB_RESOURCE_TYPES.includes(t));
  return typeFilterIncludesSubResources;
};

type FilterListPanelProps = {
  filters: Filter[];
  setFiltersInEdit: (filters: Filter[]) => void;
  setActiveFilterName: (filterName: string) => void;
  activeFilterName: string;
}

const FilterListPanel = ({ filters, setFiltersInEdit, setActiveFilterName, activeFilterName }: FilterListPanelProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const filteredFilters = filters.filter(f => f.name.toLowerCase().includes(searchQuery.toLowerCase()) && f.showInMenu !== false);
  const activeFilters = filteredFilters.filter(f => !!f.value);
  const filterGroups = [...new Set(filteredFilters.map(f => f.group))];

  const clearAll = () => {
    const newFilters = filters.map(f => ({ ...f, value: null }));
    setFiltersInEdit(newFilters);
  };

  return (
    <div className="flex flex-col gap-2 border-r border-border flex-1 p-2 text-tertiary">
      <Input
        placeholder='Search filters'
        background='bg-slate-100'
        value={searchQuery}
        height='h-8'
        onInputChange={e => setSearchQuery(e.target.value)}
        icon={<MagnifyingGlassIcon width="16" height="16" className="text-slate-400" />}
      />
      <div className="overflow-auto max-h-[340px]">
        {
          activeFilters.length > 0 && (
            <div className="flex flex-col gap-4">
              <div className="flex justify-between">
                <div className="font-semibold">Active filters</div>
                <div className="text-sm cursor-pointer" onClick={clearAll}>Clear</div>
              </div>
              <div className="flex flex-col">
                {
                  activeFilters.map(f => <FilterMenuItem isActive={f.name === activeFilterName} isSelected={true} onClick={() => setActiveFilterName(f.name)} key={f.name} filter={f} />)
                }
              </div>
            </div>
          )
        }
        <div className="flex flex-col">
          {
            filterGroups.map(group => (
              <div key={group}>
                <div className="font-semibold p-2 text-secondary">{group}</div>
                {
                  filteredFilters.filter(f => f.group === group && !activeFilters.map(af => af.name).includes(f.name)).map(f => <FilterMenuItem isActive={f.name === activeFilterName} isSelected={false} onClick={() => setActiveFilterName(f.name)} key={f.name} filter={f} />)
                }
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

const FilterMenuItem = ({ filter, onClick, isSelected, isActive }: { filter: Filter, onClick: () => void, isSelected: boolean, isActive: boolean }) => {
  return (
    <div className={`flex items-center gap-1 cursor-pointer p-2 hover:bg-slate-100 ${isActive && 'bg-slate-100'}`} onClick={onClick}>
      {filter.menuIcon}
      <div className="font-semibold">{filter.name}</div>
      {
        isSelected && <div className="text-primary ml-auto"><CheckIcon width="14" height="14" /></div>
      }
    </div>
  );
};
