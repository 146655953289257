import { BookOpenIcon, EyeIcon, ServerStackIcon, UsersIcon } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FilterIcon, PDTBuildsIcon } from "src/assets/images/icons/DelphiIcons";
import { List } from "src/components/List";
import { IExpandedNode, INodeUsage, ISourceNodeUsage, IUserNodeUsage } from "src/features/models/discover/INode";
import { formatSecondsToHoursAndMinutes } from "src/infrastructure/dateUtilities";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetDataModelResourcesQuery } from "src/services/nodes/nodes";
import { NodeUsageAppType } from "src/services/nodes/types";
import { formatNumber } from "src/infrastructure/numberUtilities";


export const ResourceSidepaneUsageTab = ({ resource, setResourceId }: { resource: IExpandedNode, setResourceId: (id: string) => void }) => {
    const openUsageDocs = () => {
        window.open('https://docs.euno.ai/data-model-screen', '_blank');
    };
    return (
        <div className="flex flex-col gap-4 bg-slate-50 p-4 h-full overflow-y-auto max-h-[80vh]">
            <div className="flex justify-between">
                <span className="font-semibold text-secondary">Usage</span>
                <div className="text-sm items-center flex gap-1 text-slate-400 hover:text-slate-500 cursor-pointer" onClick={openUsageDocs}>
                    <BookOpenIcon width="14" height="14" />
                    Learn more about usage
                </div>
            </div>
            <PDTBuilds resource={resource} />
            <Views resource={resource} />
            <ImpressionsOrQueries setResourceId={setResourceId} data={resource.impressions} label="Impressions" icon={<EyeIcon width="16" height="16" />} />
            <ImpressionsOrQueries setResourceId={setResourceId} data={resource.usage} label="Queries" icon={<ServerStackIcon width="16" height="16" />} />
        </div>
    );
};

const Views = ({ resource }: { resource: IExpandedNode }) => {
    if (!resource.last30DaysViews && !resource.last7DaysViews) return null;

    return (
        <div className="p-4 flex flex-col gap-4 bg-white rounded-lg border border-border">
            <div className="flex justify-between items-start">
                <div className="flex gap-1 text-slate-500 items-center text-base"><EyeIcon width="14" height="14" /> <span className="text-slate-800 font-semibold">Views</span></div>
                <div className="flex gap-2">
                    <TimeFrameBox label="7 days" value={formatNumber(resource.last7DaysViews ||0)} isSelected={false} />
                    <TimeFrameBox label="30 days" value={formatNumber(resource.last30DaysViews || 0)} isSelected={false} />
                </div>
            </div>
        </div>
    );
};

const PDTBuilds = ({ resource }: { resource: IExpandedNode }) => {
    if (!resource.pdtBuildsLast30d) return null;

    return (
        <div className="p-4 flex flex-col gap-4 bg-white rounded-lg border border-border">
            <div className="flex justify-between items-start">
                <div className="flex gap-1 text-slate-500 items-center text-base">
                    <PDTBuildsIcon width="14" height="14" />
                    <span className="text-slate-800 font-semibold">PDT Builds</span>
                    <span className="text-slate-400">last 30 days</span>
                </div>
                <div className="flex gap-2">
                    <TimeFrameBox label="number of builds" value={formatNumber(resource.pdtBuildsLast30d)} isSelected={false} />
                    <TimeFrameBox label="total build time" value={formatSecondsToHoursAndMinutes(resource.pdtTotalBuildTime30d || 0)} isSelected={false} />
                </div>
            </div>
        </div>
    );
};

enum TimeFrame {
    '7Days' = '7Days',
    '14Days' = '14Days',
    '30Days' = '30Days',
    '60Days' = '60Days',
}

type ImpressionsOrQueriesProps = {
    data: INodeUsage | null;
    label: string;
    icon: JSX.Element;
    setResourceId: (id: string) => void;
}

const ImpressionsOrQueries = ({ data, label, icon, setResourceId }: ImpressionsOrQueriesProps) => {
    const [timeFrame, setTimeFrame] = useState<TimeFrame>(TimeFrame['14Days']);
    if (!data) return null;
    let usersBreakdown: IUserNodeUsage[] | null = null;
    let sourcesBreakdown: ISourceNodeUsage[] | null = null;
    let distinctUsers: number | null = null;
    switch (timeFrame) {
        case TimeFrame['14Days']:
            usersBreakdown = data.users14Days || null;
            sourcesBreakdown = data.sources14Days || null;
            distinctUsers = data.distinctUsers14d || null;
            break;
        case TimeFrame['30Days']:
            usersBreakdown = data.users30Days || null;
            sourcesBreakdown = data.sources30Days || null;
            distinctUsers = data.distinctUsers30d || null;
            break;
        case TimeFrame['60Days']:
            usersBreakdown = data.users60Days || null;
            sourcesBreakdown = data.sources60Days || null;
            distinctUsers = data.distinctUsers60d || null;
            break;
    }
    return (
        <div className="p-4 flex flex-col gap-4 bg-white rounded-lg border border-border">
            <div className="flex justify-between items-start">
                <div className="flex gap-1 text-slate-500 items-center text-base">{icon} <span className="text-slate-800 font-semibold">{label}</span></div>
                <div className="flex gap-2">
                    <TimeFrameBox label="14 days" value={formatNumber(data.usage14Days || 0)} isSelected={timeFrame === TimeFrame['14Days']} onClick={() => setTimeFrame(TimeFrame['14Days'])} />
                    <TimeFrameBox label="30 days" value={formatNumber(data.usage30Days || 0)} isSelected={timeFrame === TimeFrame['30Days']} onClick={() => setTimeFrame(TimeFrame['30Days'])} />
                    <TimeFrameBox label="60 days" value={formatNumber(data.usage60Days || 0)} isSelected={timeFrame === TimeFrame['60Days']} onClick={() => setTimeFrame(TimeFrame['60Days'])} />
                </div>
            </div>
            <UsersBreakdown usersBreakdown={usersBreakdown} distinctUsers={distinctUsers} />
            <SourcesBreakdown setResourceId={setResourceId} sourcesBreakdown={sourcesBreakdown} />
        </div>
    );
};

const TimeFrameBox = ({ label, value, isSelected, onClick }: { label: string, value?: string | number, isSelected: boolean, onClick?: () => void }) => {
    if (!value && value !== 0) return null;
    return (
        <div className={`min-w-[80px] flex flex-col p-2 border rounded-lg ${onClick && 'cursor-pointer hover:bg-white'} ${isSelected ? 'border-lilac-400 bg-white' : 'border-border'}`} onClick={onClick}>
            <div className="flex gap-1 items-center">
                <div className="text-slate-500 text-sm">{label}</div>
                {
                    isSelected && <FilterIcon width="12" height="12" />
                }
            </div>
            <div className="text-text-primary font-semibold text-base">{value}</div>
        </div>
    );
};

const UsersBreakdown = ({ usersBreakdown, distinctUsers }: { usersBreakdown: IUserNodeUsage[] | null, distinctUsers: number | null }) => {
    const users = usersBreakdown || [];
    if (users.length === 0) return null;
    const label = distinctUsers && distinctUsers > 5 ? `Users (Top 5 of ${distinctUsers})` : 'Users';
    return <List isCollapsible={true} defaultCollapsed={false} label={label} icon={<UsersIcon width="14" height="14" />} items={[...users].sort((a, b) => b.number - a.number).map(u => ({ title: u.name, value: u.number, subtitle: u.email }))} />;
};

const SourcesBreakdown = ({ sourcesBreakdown, setResourceId }: { sourcesBreakdown: ISourceNodeUsage[] | null, setResourceId: (resourceId: string) => void }) => {
    const accountId = useSelector(selectActiveAccountId);
    const sources = useMemo(() => sourcesBreakdown || [], [sourcesBreakdown]);
    const sourceResources = useGetDataModelResourcesQuery({
        accountId,
        page: 1,
        pageSize: 100,
        eql: `uri in (${sources.map(s => s.utl).filter(u => !!u).map(utl => `'${utl}'`).join(',')})`
    }, { skip: sources.length === 0 });
    const sourcesWithTitles = useMemo(() => sources.map(s => {
        const utl = s?.utl || '';
        let title = sourceResources.data?.items.find(r => r.id === utl)?.name || 'Other';
        let subtitle = sourceTypeMap[s.type] || '';
        if (s.type == NodeUsageAppType.other) {
            subtitle = '';
        } else if (s.type === NodeUsageAppType.explore) {
            title = 'Explore';
            subtitle = '';
        } else if (s.dashboardTitle) {
            subtitle = `${subtitle}: ${s.dashboardTitle}`;
        }
        return { ...s, title, subtitle };
    }), [sources, sourceResources.data]);
    if (sources.length === 0) return null;
    return (
        <List
            label="Sources (Top 5)"
            isCollapsible={true}
            defaultCollapsed={false}
            icon={<ServerStackIcon width="14" height="14" />}
            items={sourcesWithTitles.sort((a, b) => b.number - a.number).map(s => ({
                onClick: () => s.utl && setResourceId(s.utl),
                title: s.title,
                value: s.number,
                subtitle: s.subtitle
            }))}
        />
    );
};

const sourceTypeMap = {
    [NodeUsageAppType.look]: 'Look',
    [NodeUsageAppType.dashboard_element]: 'Tile',
    [NodeUsageAppType.api_query]: 'API',
    [NodeUsageAppType.explore]: 'Explore',
    [NodeUsageAppType.other]: 'Other'
};
