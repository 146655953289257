import { useCallback, useEffect, useRef } from 'react';
import { useClickAway, useKeyPress } from 'react-use';
import { Filter } from './types';
import Button from 'src/components/button/Button';
import { ButtonTypes } from 'src/components/button/types';

interface FilterPanelProps {
  filters: Filter[];
  updateFilters: (filters: Filter[]) => void;
  filterToShow: Filter | null;
  setFilterToShow: (filter: Filter | null) => void;
}

export const FilterPanel = ({ filters, updateFilters, filterToShow, setFilterToShow }: FilterPanelProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const escapePressed = useKeyPress('Escape');

  const onClose = useCallback(() => setFilterToShow(null),  [setFilterToShow]);

  useClickAway(ref, onClose);

  useEffect(() => {
    if (escapePressed[0]) {
      onClose();
    }
  }, [escapePressed, onClose]);

  const onFilterChange = (value: string | null) => {
    if (filterToShow) {
      setFilterToShow({ ...filterToShow, value });
    }
  };

  const onSave = () => {
    if (filterToShow) {
      const newFilters = filters.map((f) => (f.name === filterToShow.name ? filterToShow : f));
      updateFilters(newFilters);
    }
  };

  if (!filterToShow || !filterToShow.component) {
    return null;
  }

  return (
    <div className="absolute top-0 z-20 w-64 rounded-md border border-slate-200 bg-white shadow" ref={ref}>
      <div>
        {
          <filterToShow.component
            value={filterToShow.value}
            onChange={onFilterChange}
          />
        }
      </div>
      <div className="flex gap-2 p-2 flex-row-reverse">
        <Button text="Save" type={ButtonTypes.primary} onClick={() => {onSave(); onClose();}} />
        <Button text="Close" type={ButtonTypes.secondary} onClick={onClose} />
      </div>
    </div>
  );
};