import { useState } from "react";
import CreateProjectModal from "./create/CreateProjectModal";
import { ProjectIcon } from "../../assets/images/icons/DelphiIcons";
import { useAppDispatch } from "../../infrastructure/state/hooks";
import activeProjectSlice, { selectActiveProject } from "../../infrastructure/state/slices/activeProjectSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetAccountProjectsQuery } from "../../services/projects/projects";
import { selectActiveAccountId } from "../../infrastructure/state/slices/activeAccountSlice";
import { ChevronDownIcon, ChevronUpIcon, PlusIcon } from "@heroicons/react/24/outline";

export const SidebarProjectsList = () => {
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const accountId = useSelector(selectActiveAccountId);
    const getAccountProjectsQuery = useGetAccountProjectsQuery({ accountId });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const activeProjectId = useSelector(selectActiveProject);

    const selectProject = (id: number) => {
        dispatch(activeProjectSlice.actions.setActiveProject(id));
        navigate(`/project/${id}`);
    };

    return (
        <div className="p-2 overflow-auto border-t border-border">
            <div className="flex justify-between text-slate-400 px-2 cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
                <div>
                    PROJECTS
                </div>
                <div className="w-fit text-secondary" >
                    {
                        isExpanded ? <ChevronUpIcon width="20" height="20" /> : <ChevronDownIcon width="20" height="20" />
                    }
                </div>
            </div>
            <div>
                <div className={`flex flex-col gap-2 overflow-auto transition-all ${isExpanded ? 'max-h-[100px]' : 'max-h-[0px]'}`}>
                    {
                        (getAccountProjectsQuery?.data || []).map(project => (
                            <div className={`px-2 py-1 w-fit flex gap-2 items-center cursor-pointer hover:bg-slate-100 rounded ${project.id === activeProjectId ? 'bg-slate-100' : 'bg-white'}`} onClick={() => selectProject(project.id)} key={project.id}>
                                <div>
                                    <ProjectIcon width="12" height="12" fill="#94A3B8" />
                                </div>
                                <div className="text-secondary">
                                    {project.name}
                                </div>
                            </div>
                        ))
                    }
                </div>
                {
                    isExpanded && (
                        <>
                            <div className="w-full h-[1px] bg-border my-2"></div>
                            <div className="text-secondary cursor-pointer flex gap-1 items-center py-2 hover:bg-slate-100 rounded" onClick={() => setShowCreateProjectModal(true)}>
                                <PlusIcon width="16" height="16" />
                                Create project
                            </div>
                        </>
                    )
                }
            </div>
            <CreateProjectModal isOpen={showCreateProjectModal} onClose={() => setShowCreateProjectModal(false)} />
        </div>
    );
};