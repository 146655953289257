import { useCallback, useMemo, useState } from 'react';
import Layout from '../../../components/layout/Layout';
import { ISuperficialNode } from './INode';
import { DiscoverView, FilterMode, UpdateDataModelStateProps } from './types';
import { DiscoverToolbar } from './toolbar/DiscoverToolbar';
import { DiscoverTableView } from './view/table/DiscoverTableView';
import { DiscoverDAGView } from './view/DAG/DiscoverDAGView';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ExpandedNode } from './view/DAG/types';
import { selectShowSubResourcesInDataModelTable } from 'src/infrastructure/state/slices/showSubResouresInDataModelTableSlice';
import { ResourceSidepane } from 'src/features/models/discover/resourceSidepane/ResourceSidepane';
import { Filter } from 'src/features/models/discover/toolbar/filters/Types';
import { allFilters } from 'src/features/models/discover/toolbar/filters/DiscoverFilterList';
import { selectActiveAccountId } from 'src/infrastructure/state/slices/activeAccountSlice';
import { useGetCustomPropertiesQuery } from 'src/services/nodes/nodes';
import { getCustomFilters } from './toolbar/filters/customFilters';

export const Discover = () => {
  const [selectedNode, setSelectedNode] = useState<ISuperficialNode | null>(null);
  const [nodeSidepane, setNodeSidepane] = useState<ISuperficialNode | null>(null);
  const [expandedNodes, setExpandedNodes] = useState<ExpandedNode[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [eqlErrorMessage, setEqlErrorMessage] = useState<string | null>(null);
  const view = useMemo(() => searchParams.get('view') as DiscoverView || DiscoverView.Table, [searchParams]);
  const showSubResources = useSelector(selectShowSubResourcesInDataModelTable);
  const filterMode = useMemo<FilterMode>(() => searchParams.get('eql') !== null ? 'eql' : 'basic', [searchParams]);
  const [isLoadingResources, setIsLoadingResources] = useState(false);
  const accountId = useSelector(selectActiveAccountId);
  const getCustomPropertiesQuery = useGetCustomPropertiesQuery({ accountId });

  const customFilters = useMemo(() => {
        const customFilters = getCustomFilters(getCustomPropertiesQuery.data || []);
        return customFilters;
    }, [getCustomPropertiesQuery.data]);

  const eql = useMemo(() => {
    if (searchParams.get('eql')) {
      return searchParams.get('eql') || '';
    }
    else {
      const filters = getFilterListFromUrl(searchParams, customFilters);
      return getEqlFromFilters(filters);
    }
  }, [searchParams, customFilters]);


  const updateDataModelState = useCallback(({ filters, eql, columns, view }: UpdateDataModelStateProps) => {
    if (filters) {
      for (const filter of filters) {
        if (!filter.isDisabled && !!filter.value) {
          searchParams.set(filter.name, filter.value);
        }
        else {
          searchParams.delete(filter.name);
        }
      }
      searchParams.delete('eql');
    }
    if (eql) {
      searchParams.set('eql', eql);
    }
    if (columns) {
      searchParams.set('columns', columns.join(','));
    }
    if (view) {
      searchParams.set('view', view);
    }
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  return (
    <Layout>
      <DiscoverToolbar
        setSelectedSubNode={(parentNode) => setSelectedNode(parentNode)}
        setSelectedNode={node => { setSelectedNode(node); setNodeSidepane(node); }}
        view={view}
        updateDataModelState={updateDataModelState}
        eql={eql}
        showSubResources={showSubResources}
        expandedNodes={expandedNodes}
        setExpandedNodes={setExpandedNodes}
        eqlErrorMessage={eqlErrorMessage}
        filterMode={filterMode}
        customFilters={customFilters}
        isLoadingResources={isLoadingResources}
      />
      {
        view === DiscoverView.Graph && (
          <DiscoverDAGView
            setShowNodeSidepane={(node: ISuperficialNode | null) => setNodeSidepane(node)}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            eql={eql}
            setExpandedNodes={setExpandedNodes}
            expandedNodes={expandedNodes}
            setEqlErrorMessage={setEqlErrorMessage}
            setIsLoadingResources={setIsLoadingResources}
            isLoadingResources={isLoadingResources}
          />
        )
      }
      {
        view === DiscoverView.Table && (
          <DiscoverTableView
            setSelectedNode={setSelectedNode}
            showSubResources={showSubResources}
            setShowNodeSidepane={(node: ISuperficialNode | null) => setNodeSidepane(node)}
            eql={eql}
            setEqlErrorMessage={setEqlErrorMessage}
            filterMode={filterMode}
            setIsLoadingResources={setIsLoadingResources}
            isLoadingResources={isLoadingResources}
            updateDataModelState={updateDataModelState}
          />
        )
      }
      <ResourceSidepane resourceId={nodeSidepane?.id || ''} onClose={() => setNodeSidepane(null)} />
    </Layout>
  );
};

const getEqlFromFilters = (filters: Filter[]): string => {
  return filters.filter((f) => !f.isDisabled && !!f.value).map((f) => f.getEql(f.value)).filter(Boolean).join(' AND ');
};

const getFilterListFromUrl = (searchParams: URLSearchParams, customFilters: Filter[]): Filter[] => {
  return [...customFilters, ...allFilters].map(f => ({ ...f, value: searchParams.get(f.name) || null }));
};
