import { TableView } from "src/services/accounts/types";

export type DataModelTableView = TableView & { config: DataModelTabConfig };

export type DataModelTabConfig = {
    showSubResources?: boolean;
    eql?: string;
    filters?: { name: string; value: string | null }[];
    filterMode: 'basic' | 'eql';
    columns?: string[];
}

export const DATA_MODEL_TABLE_KEY = 'dataModel';