import Xarrow, { Xwrapper } from 'react-xarrows';
import { ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { getNodeUrl } from "src/features/models/discover/getNodeUrl";
import { IExpandedNode, NodeType } from "src/features/models/discover/INode";
import { NodeIcon } from "src/features/models/discover/NodeIcon";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetDataModelResourcesQuery } from "src/services/nodes/nodes";
import { getNodeIdForDom } from 'src/features/models/discover/getNodeIdForDom';
import { ComponentLoader } from 'src/components/loaders/ComponentLoader';

export const ResourceSidepaneOverviewConnections = ({ resource, setResourceId }: { resource: IExpandedNode, setResourceId: (id: string) => void }) => {
    const goToDataModel = () => {
        window.open(getNodeUrl(resource.id), '_blank');
    };
    const accountId = useSelector(selectActiveAccountId);
    const getUpstreamResources = useGetDataModelResourcesQuery({ accountId, page: 1, pageSize: 20, eql: `has downstream(uri='${resource.id}', 1)` });
    const getDownstreamResources = useGetDataModelResourcesQuery({ accountId, page: 1, pageSize: 20, eql: `has upstream(uri='${resource.id}', 1)` });
    const upstreamResources = getUpstreamResources.data?.items || [];
    const downstreamResources = getDownstreamResources.data?.items || [];
    if (getUpstreamResources.isLoading || getDownstreamResources.isLoading) return <ComponentLoader className="justify-end flex-1"/>;
    if (upstreamResources.length === 0 && downstreamResources.length === 0) return null;
    return (
        <div className="p-4 rounded-bl-lg flex flex-col gap-4 flex-1 mt-4 justify-end">
            <div className="flex items-center justify-between">
                <div className="font-semibold">Connections</div>
                <Button onClick={goToDataModel} type={ButtonTypes.secondary} text={"Isolate in DAG"} icon={<ViewfinderCircleIcon width="16" height="16" />} />
            </div>
            <Xwrapper>
                <div className="border border-border rounded-lg overflow-y-auto max-h-[200px] relative bg-white overflow-x-hidden min-h-[350px]">
                    <div className="flex-1 bg-gradient-to-b from-lilac-50 h-full absolute w-1/3"></div>
                    <div className="flex-1 bg-gradient-to-b from-lilac-50 h-full absolute w-1/3 ml-[67%]"></div>
                    <div className="flex mt-2 relative">
                        <div className="flex-1 mx-2">
                            <div className="bg-surface-light text-tertiary rounded w-fit px-2 py-1">
                                UPSTREAM
                            </div>
                        </div>
                        <div className="flex-1"></div>
                        <div className="flex-1 mx-2">
                            <div className="bg-surface-light text-tertiary rounded w-fit px-2 py-1">
                                DOWNSTREAM
                            </div>
                        </div>
                    </div>
                    <div className="my-2 flex w-full justify-between relative">
                        <div className="flex flex-col gap-1 flex-1">
                            {
                                upstreamResources.map(r => <NodeConnection id={r.id} key={r.id} name={r.name} type={r.type} setResourceId={setResourceId} />)
                            }
                        </div>
                        <div className="flex-1">
                            <NodeConnection id={resource.id} name={resource.name} type={resource.type} setResourceId={setResourceId} className="border-primary !bg-lilac-50 mx-auto" />
                        </div>
                        <div className="flex flex-col gap-1 flex-1">
                            {
                                downstreamResources.map(r => <NodeConnection id={r.id} key={r.id} name={r.name} type={r.type} setResourceId={setResourceId} />)
                            }
                        </div>
                        {
                            upstreamResources.map(r => (
                                <Xarrow
                                    key={`${resource.id}-${r.id}`}
                                    start={getNodeIdForDom(r.id, 'overview')}
                                    end={getNodeIdForDom(resource.id, 'overview')}
                                    startAnchor="right"
                                    endAnchor="left"
                                    strokeWidth={1}
                                    showHead={false}
                                    color={'#CBD5E1'}
                                />
                            ))
                        }
                        {
                            downstreamResources.map(r => (
                                <Xarrow
                                    key={`${resource.id}-${r.id}`}
                                    start={getNodeIdForDom(resource.id, 'overview')}
                                    end={getNodeIdForDom(r.id, 'overview')}
                                    startAnchor="right"
                                    endAnchor="left"
                                    strokeWidth={1}
                                    showHead={false}
                                    color={'#CBD5E1'}
                                />
                            ))
                        }
                    </div>
                </div>
            </Xwrapper>
        </div>
    );
};

const NodeConnection = ({ name, type, id, setResourceId, className = '' }: { id: string; name: string, type: NodeType, setResourceId: (id: string) => void, className?: string }) => {
    const onClick = () => {
        setResourceId(id);
    };
    return (
        <div id={getNodeIdForDom(id, 'overview')} className={`w-40 mx-auto flex gap-1 items-center text-text-primary rounded-md border cursor-pointer border-border bg-white px-2 py-1 ${className}`} onClick={onClick}>
            <NodeIcon type={type} greyVersion={true} />
            <div className="whitespace-nowrap overflow-hidden text-ellipsis">{name}</div>
        </div>
    );
};