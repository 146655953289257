import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export default createSlice({
  name: 'isModalOpen',
  initialState: false,
  reducers: {
    setIsModalOpen: (_state, action: PayloadAction<boolean>) => {
      return action.payload;
    }
  }
});

export const selectIsModalOpen = (state: RootState) => state.isModalOpen;
