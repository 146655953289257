import { WeekDay } from "src/services/integrations/types";

interface WeekDaysProps {
  onChange: (day: WeekDay) => void;
  selected?: WeekDay[];
  className?: string;
}

export const WeekDays = ({ selected, onChange, className }: WeekDaysProps) => {
  const dayClassName = `flex justify-center items-center text-center cursor-pointer w-9 h-9 mr-1.5 border rounded-full ${className}`;
  return (
    <div className='flex'>
      {Object.values(WeekDay).map(day => (
        <div
          key={`select-${day}`}
          className={`${dayClassName} ${selected?.includes(day) ? 'bg-lilac-700 text-white' : ''}`}
          onClick={() => onChange(day)}>
          {day[0]}
        </div>
      ))}
    </div>
  );
};
