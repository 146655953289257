import { BookOpenIcon, CodeBracketIcon } from "@heroicons/react/24/outline";
import { FolderIcon, TagIcon } from "@heroicons/react/24/solid";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useSelector } from "react-redux";
import Xarrow from "react-xarrows";
import { BranchIcon, DbtCloudIcon, EunoIcon, GithubIcon, LookerIcon } from "src/assets/images/icons/DelphiIcons";
import Input from "src/components/form/Input";
import RadioButton from "src/components/form/RadioButton";
import Select, { Option } from "src/components/form/Select";
import { Toggle } from "src/components/Toggle";
import { SyncJob, SyncTarget } from "src/features/dataModelSync/types";
import { eunoLinks } from "src/features/eunoLinks";
import { SQLDialect } from "src/features/projects/IProject";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { emptySyncTarget } from "src/services/actions/transformers";
import { useGetGithubBranchesQuery, useGetGithubIntegrationsQuery, useGetGithubRepositoriesQuery } from "src/services/integrations/integrations";
import { useGetFacetsQuery } from "src/services/nodes/nodes";
import { useGetAccountProjectsQuery } from "src/services/projects/projects";

type SyncJobFormProps = {
    syncJob: SyncJob;
    setSyncJob: Dispatch<SetStateAction<SyncJob | null>>;
    syncTarget: SyncTarget | null;
    setSyncTarget: Dispatch<SetStateAction<SyncTarget | null>>;
}

export const SyncJobForm = ({ syncJob, setSyncJob, syncTarget, setSyncTarget }: SyncJobFormProps) => {
    return (
        <div className="flex flex-col gap-12 w-fit mx-auto flex-1 p-4 overflow-y-auto overflow-x-hidden text-text-primary">
            <General syncJob={syncJob} setSyncJob={setSyncJob} />
            <Trigger syncJob={syncJob} setSyncJob={setSyncJob} />
            <Target syncJob={syncJob} setSyncJob={setSyncJob} syncTarget={syncTarget} setSyncTarget={setSyncTarget} />
            <SyncContents syncJob={syncJob} setSyncJob={setSyncJob} />
        </div>
    );
};

const General = ({ syncJob, setSyncJob }: { syncJob: SyncJob; setSyncJob: (syncJob: SyncJob) => void }) => {
    return (
        <div className="flex flex-col gap-4">
            <div className="text-lg">General</div>
            <div className="rounded border border-border px-10 py-6">
                <div className="flex items-center gap-16 mx-auto w-fit">
                    <Xarrow start="main-branch" end="lookml-repository" strokeWidth={1} />
                    <div id="main-branch" className="flex gap-2 rounded-full border border-border bg-white items-center px-6 py-2">
                        <BranchIcon width="14" height="14" />
                        Main branch
                    </div>
                    <div id="lookml-repository" className="flex gap-2 rounded-full border border-border bg-white items-center px-6 py-2">
                        <LookerIcon width="14" height="14" />
                        LookML Repository
                    </div>
                </div>
                <div className="text-sm text-tertiary mt-4">
                    Configure LookML destination to automatically keep your Looker model consistent with a branch of your dbt project.
                </div>
            </div>
            <div className="flex justify-between items-center whitespace-nowrap">
                <div>Job name</div>
                <Input
                    value={syncJob.name}
                    onInputChange={(e) => setSyncJob({ ...syncJob, name: e.target.value })}
                    placeholder="Enter sync job name"
                    className="!w-[450px]"
                />
            </div>
            <div className="flex justify-between items-center whitespace-nowrap">
                <div>SQL Dialect</div>
                <Select
                    options={Object.values(SQLDialect).map((dialect) => ({ label: dialect, value: dialect }))}
                    value={syncJob.configuration.sqlDialect}
                    onChange={option => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, sqlDialect: (option as Option).value as SQLDialect } })}
                    className="!w-[450px] border border-border rounded shadow hover:border-primary"
                    icon={<CodeBracketIcon width="16" height="16" className="text-slate-400" />}
                />
            </div>
        </div>
    );
};

const Trigger = ({ syncJob, setSyncJob }: { syncJob: SyncJob; setSyncJob: (syncJob: SyncJob) => void }) => {
    const accountId = useSelector(selectActiveAccountId);
    const getProjects = useGetAccountProjectsQuery({ accountId });
    const projects = useMemo(() => getProjects.data || [], [getProjects.data]);

    return (
        <div className="flex flex-col gap-4">
            <div className="text-lg">Select sync trigger</div>
            <div className="flex justify-between items-center whitespace-nowrap">
                <div>
                    <div>Automatically sync data model</div>
                    <div className="text-sm text-tertiary">Sync the data model every time there is a new dbt build</div>
                </div>
                <Toggle onChange={() => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, automaticallySyncDataModel: !syncJob.configuration.automaticallySyncDataModel } })} value={syncJob.configuration.automaticallySyncDataModel} />
            </div>
            {
                syncJob.configuration.automaticallySyncDataModel && (
                    <div className="flex justify-between items-center whitespace-nowrap">
                        <div>Euno project</div>
                        <Select
                            options={projects.map(project => ({ label: project.name, value: project.id }))}
                            value={syncJob.configuration.eunoProjectId}
                            onChange={option => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, eunoProjectId: (option as Option).value as number } })}
                            className="!w-[450px] border border-border rounded shadow hover:border-primary"
                            placeholder="Select Euno project"
                            icon={<EunoIcon width="16" height="16" className="text-slate-400" />}
                        />
                    </div>
                )
            }
        </div>
    );
};

const Target = ({ syncJob, setSyncJob, syncTarget, setSyncTarget }: SyncJobFormProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const githubIntegrationsQuery = useGetGithubIntegrationsQuery({ accountId });
    const githubAccountId = useMemo(() => githubIntegrationsQuery.data?.[0]?.githubAccountId || 0, [githubIntegrationsQuery.data]);
    const repositoriesQuery = useGetGithubRepositoriesQuery({ accountId, githubAccountId }, { skip: !githubAccountId });
    const repositories = useMemo(() => repositoriesQuery.data || [], [repositoriesQuery.data]);
    const branchesQuery = useGetGithubBranchesQuery({ repo: repositories.find(r => r.cloneUrl === syncTarget?.configuration.targetRepositoryUrl)?.name || '', githubAccountId, accountId }, { skip: !syncTarget?.configuration.targetRepositoryUrl });
    const branches = useMemo(() => branchesQuery.data || [], [branchesQuery.data]);

    const updateTargetRepositoryUrl = async (repositoryUrl: string) => {
        if (syncTarget) {
            setSyncTarget({ ...syncTarget, configuration: { ...syncTarget.configuration, targetRepositoryUrl: repositoryUrl } });
        }
        setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, targetBranch: '' } });
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="text-lg">Target Information</div>
            <div className="flex justify-between items-center whitespace-nowrap">
                <div>
                    <div className="text-sm text-tertiary">If no target is selected, the sync output (LookML files) will be downloaded locally only</div>
                </div>
                <Toggle onChange={() => setSyncTarget(syncTarget => syncTarget ? null : emptySyncTarget)} value={!!syncTarget} />
            </div>
            {syncTarget && (
                <>
                    <div className="flex justify-between items-center whitespace-nowrap">
                        <div>Target repository</div>
                        <Select
                            options={repositories.map(repository => ({ label: repository.name, value: repository.cloneUrl }))}
                            value={syncTarget.configuration.targetRepositoryUrl}
                            onChange={option => updateTargetRepositoryUrl((option as Option).value as string)}
                            className="!w-[450px] border border-border rounded shadow hover:border-primary"
                            placeholder="Select target repository"
                            icon={<GithubIcon width="16" height="16" className="text-slate-400" />}
                        />
                    </div>
                    <div className="flex justify-between items-center whitespace-nowrap">
                        <div>Target branch</div>
                        <Select
                            options={branches.map(branch => ({ label: branch.name, value: branch.name }))}
                            value={syncJob.configuration.targetBranch}
                            onChange={option => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, targetBranch: (option as Option).value as string } })}
                            className="!w-[450px] border border-border rounded shadow hover:border-primary"
                            placeholder="Select target branch"
                            isLoading={branchesQuery.isFetching}
                            icon={<BranchIcon width="16" height="16" className="text-slate-400" />}
                        />
                    </div>
                    <div className="flex justify-between items-center whitespace-nowrap">
                        <div>Target directory</div>
                        <Input
                            value={syncJob.configuration.targetDirectory}
                            onInputChange={(e) => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, targetDirectory: e.target.value } })}
                            placeholder="Enter target directory"
                            className="!w-[450px]"
                            icon={<FolderIcon width="16" height="16" className="text-slate-400" />}
                        />
                    </div>
                    <div className="flex flex-col">
                        <div>Merge</div>
                        <div className="flex gap-2">
                            <RadioButton value={syncJob.configuration.pushType === 'commit'} setValue={() => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, pushType: 'commit' } })} />
                            <div className="flex flex-col mt-4">
                                <div>Commit directly to branch</div>
                                <div className="text-sm text-tertiary">Changes will automatically be pushed without a review.</div>
                            </div>
                        </div>
                        <div className="flex gap-2">
                            <RadioButton value={syncJob.configuration.pushType === 'pull_request'} setValue={() => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, pushType: 'pull_request' } })} />
                            <div className="flex flex-col mt-4">
                                <div>Create pull request</div>
                                <div className="text-sm text-tertiary">The changes will need to be merged to take effect.</div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

const SyncContents = ({ syncJob, setSyncJob }: { syncJob: SyncJob; setSyncJob: (syncJob: SyncJob) => void }) => {
    const accountId = useSelector(selectActiveAccountId);
    const facets = useGetFacetsQuery({ accountId });
    const dbtProjects = useMemo(() => facets.data?.dbt_project || [], [facets.data]);

    return (

        <div className="flex flex-col gap-4">
            <div className="text-lg">What to sync</div>
            <div className="flex justify-between items-center whitespace-nowrap">
                <div>dbt project</div>
                <Select
                    options={dbtProjects.map(name => ({ label: name, value: name }))}
                    value={syncJob.configuration.dbtProjectName}
                    onChange={option => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, dbtProjectName: (option as Option).value as string } })}
                    className="!w-[450px] border border-border rounded shadow hover:border-primary"
                    placeholder="Select dbt project"
                    icon={<DbtCloudIcon width="14" height="14" className="text-slate-400" />}
                />
            </div>
            <div className="flex flex-col">
                <div>Include euno links</div>
                <div className="flex gap-2">
                    <RadioButton value={syncJob.configuration.includeEunoLinks === 'include_links'} setValue={() => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, includeEunoLinks: 'include_links' } })} />
                    <div className="flex flex-col mt-4">
                        <div>Include links</div>
                        <div className="text-sm text-tertiary">A link to the Euno resource will be added to the LookML synced field under the <span className="underline text-primary cursor-pointer" onClick={() => window.open('https://cloud.google.com/looker/docs/reference/param-field-link', '_blank')}>link property</span></div>
                    </div>
                </div>
                <div className="flex gap-2">
                    <RadioButton value={syncJob.configuration.includeEunoLinks === 'include_links_in_description'} setValue={() => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, includeEunoLinks: 'include_links_in_description' } })} />
                    <div className="flex flex-col mt-4">
                        <div>Include links in description</div>
                        <div className="text-sm text-tertiary">A link to the Euno resource will be added to the LookML synced field under the <span className="underline text-primary cursor-pointer" onClick={() => window.open('https://cloud.google.com/looker/docs/reference/param-field-description', '_blank')}>description property</span></div>
                    </div>
                </div>
                <div className="flex gap-2">
                    <RadioButton value={syncJob.configuration.includeEunoLinks === 'exclude_links'} setValue={() => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, includeEunoLinks: 'exclude_links' } })} />
                    <div className="flex flex-col mt-4">
                        <div>Exclude links</div>
                        <div className="text-sm text-tertiary">No Euno links will added as part of the sync</div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <div>Models</div>
                <div className="flex gap-2">
                    <RadioButton value={syncJob.configuration.modelSyncType === 'all'} setValue={() => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, modelSyncType: 'all' } })} />
                    <div className="flex flex-col mt-4">
                        <div>All models</div>
                        <div className="text-sm text-tertiary">Include all the dbt models in the sync operation</div>
                    </div>
                </div>
                <div className="flex gap-2">
                    <RadioButton value={syncJob.configuration.modelSyncType === 'selected_models'} setValue={() => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, modelSyncType: 'selected_models' } })} />
                    <div className="flex flex-col mt-4">
                        <div>Specific models</div>
                        <div className="text-sm text-tertiary">Only the selected models will be included in the sync operation</div>
                    </div>
                </div>
                {
                    syncJob.configuration.modelSyncType === 'selected_models' && (
                        <Select
                            value={syncJob.configuration.selectedModels}
                            options={syncJob.configuration.selectedModels.map(model => ({ label: model, value: model }))}
                            placeholder="Add model names"
                            isCreatable
                            isMulti
                            onChange={(models) => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, selectedModels: (models as Option[]).map((model: Option) => `${model.value}`) } })}
                            className="text-slate-400 border border-border rounded shadow hover:border-primary"
                        />
                    )
                }
                <div className="flex gap-2">
                    <RadioButton value={syncJob.configuration.modelSyncType === 'selected_tags'} setValue={() => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, modelSyncType: 'selected_tags' } })} />
                    <div className="flex flex-col mt-4">
                        <div>Models with specific tags</div>
                        <div className="text-sm text-tertiary">Only dbt models with at least one of the below input tags will be included in the sync operation</div>
                    </div>
                </div>
                {
                    syncJob.configuration.modelSyncType === 'selected_tags' && (
                        <Select
                            value={syncJob.configuration.selectedModelTags}
                            options={syncJob.configuration.selectedModelTags.map(model => ({ label: model, value: model }))}
                            placeholder="Add tags"
                            isCreatable
                            isMulti
                            onChange={(models) => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, selectedModelTags: (models as Option[]).map((model: Option) => `${model.value}`) } })}
                            className="text-slate-400 border border-border rounded shadow hover:border-primary"
                            icon={<TagIcon width="16" height="16" className="text-gray-400" />}
                        />
                    )
                }
            </div>
            <div className="flex flex-col">
                <div className="flex gap-1 items-center">
                    Metrics
                </div>
                <div className="flex gap-2">
                    <RadioButton value={syncJob.configuration.metricSyncType === 'all'} setValue={() => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, metricSyncType: 'all' } })} />
                    <div className="flex flex-col mt-4">
                        <div>All metrics</div>
                        <div className="text-sm text-tertiary flex items-center gap-1">Include all <span className="underline text-primary cursor-pointer flex items-center gap-0.5" onClick={() => window.open(eunoLinks.DATA_APPLICATION_SYNC_METRICS_DOCUMENTATION, '_blank')}>supported <BookOpenIcon width="12" height="12" /></span> dbt metrics associated with the dbt models chosen for sync</div>
                    </div>
                </div>
                <div className="flex gap-2">
                    <RadioButton value={syncJob.configuration.metricSyncType === 'none'} setValue={() => setSyncJob({ ...syncJob, configuration: { ...syncJob.configuration, metricSyncType: 'none' } })} />
                    <div className="flex flex-col mt-4">
                        <div>No metrics</div>
                        <div className="text-sm text-tertiary">&nbsp;</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
