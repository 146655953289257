import { AdjustmentsVerticalIcon, Bars3Icon } from '@heroicons/react/24/solid';
import Button from '../../button/Button';
import { ButtonTypes } from '../../button/types';
import { useState, useRef, useEffect } from 'react';
import { DropResult, DragDropContext, Draggable, DroppableProps, Droppable } from 'react-beautiful-dnd';
import { useClickAway } from 'react-use';
import { TableColumnProps } from '../types';
import { events, trackEvent } from 'src/infrastructure/analytics';
import Checkbox from 'src/components/form/Checkbox';
import { ArrowToLineIcon } from 'src/assets/images/icons/DelphiIcons';
import Input from 'src/components/form/Input';
import { MagnifyingGlassIcon, BarsArrowUpIcon} from '@heroicons/react/24/outline';

type SelectorColumnProps<T> = {
  columns: TableColumnProps<T>[];
  updateColumns: (columns: TableColumnProps<T>[]) => void;
  selectedColumns: TableColumnProps<T>[];
  tableName: string;
};

export const SelectorColumn = <T,>({ columns, updateColumns, selectedColumns, tableName }: SelectorColumnProps<T>) => {
  const [showSelector, setShowSelector] = useState(false);
  const [selectedHiddenColumns, setSelectedHiddenColumns] = useState<string[]>([]);
  const [selectedShownColumns, setSelectedShownColumns] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const selectorRef = useRef<HTMLDivElement>(null);
  const hideSelector = () => setShowSelector(false);
  useClickAway(selectorRef, hideSelector);

  const showSelected = () => {
    updateColumns([...selectedColumns, ...columns.filter((c) => selectedHiddenColumns.includes(c.name))]);
    setSelectedHiddenColumns([]);
  };

  const hideSelected = () => {
    updateColumns(selectedColumns.filter((c) => !selectedShownColumns.includes(c.name)));
    setSelectedShownColumns([]);
  };

  return (
    <div>
      <div id="select-columns-button" onClick={() => setShowSelector(true)} className="p-1 hover:bg-slate-50 bg-white cursor-pointer rounded border border-border text-secondary w-fit h-fit">
        <AdjustmentsVerticalIcon width="16" height="16" />
      </div>
      {showSelector && (
        <div
          ref={selectorRef}
          className="absolute bottom-0 z-20 h-[540px] min-w-[680px] rounded-md border border-slate-200 bg-white p-2 shadow">
          <div className="flex items-center gap-1 text-text-primary">
            <AdjustmentsVerticalIcon height={16} width={16} />
            Customize columns
          </div>
          <div className="mt-4 flex h-[420px] gap-4">
            <div className="flex-1 min-w-[240px]">
              <div className="mb-1 text-sm text-tertiary">Select columns to show</div>
              <HiddenColumnsList
                columns={columns}
                selectedColumns={selectedColumns}
                selectedHiddenColumns={selectedHiddenColumns}
                setSelectedHiddenColumns={setSelectedHiddenColumns}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
              />
            </div>
            <div className="mt-6 flex flex-col gap-2">
              <div
                className="flex cursor-pointer flex-col items-center gap-1 rounded-lg border border-border bg-white px-6 py-2 text-text-primary hover:bg-slate-50"
                onClick={showSelected}>
                <ArrowToLineIcon width={16} height={16} />
                Show
              </div>
              <div
                className="flex cursor-pointer flex-col items-center gap-1 rounded-lg border border-border bg-white px-6 py-2 text-text-primary hover:bg-slate-50"
                onClick={hideSelected}>
                <ArrowToLineIcon className="rotate-180" width={16} height={16} />
                Hide
              </div>
            </div>
            <div className="flex-1 min-w-[240px]">
              <div className="mb-1 text-sm text-tertiary">Drag the column handles to reorder</div>
              <DraggableColumList
                tableName={tableName}
                columns={selectedColumns}
                updateColumns={updateColumns}
                selectedShownColumns={selectedShownColumns}
                setSelectedShownColumns={setSelectedShownColumns}
              />
            </div>
          </div>
          <div className="mt-8 flex justify-end">
            <Button className="mb-2" type={ButtonTypes.secondary} text="Done" onClick={() => hideSelector()}></Button>
          </div>
        </div>
      )}
    </div>
  );
};

type DraggableColumnListProps<T> = {
  columns: TableColumnProps<T>[];
  updateColumns: (columns: TableColumnProps<T>[]) => void;
  tableName: string;
  selectedShownColumns: string[];
  setSelectedShownColumns: (columns: string[]) => void;
};

const DraggableColumList = <T,>({
  columns,
  updateColumns,
  tableName,
  selectedShownColumns,
  setSelectedShownColumns
}: DraggableColumnListProps<T>) => {
  const [hiddenSearchQuery, setHiddenSearchQuery] = useState<string>('');
  const [canMoveup, setCanMoveUp] = useState<boolean>(false);

  useEffect(() => {
    if (selectedShownColumns.length > 0) {
      setCanMoveUp(true);
    }else{
      setCanMoveUp(false);
    }
  }, [selectedShownColumns, canMoveup]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const newColumns = Array.from(columns);
    const [reorderedItem] = newColumns.splice(result.source.index, 1);
    trackEvent(events.dataModelColumnReordered, { column: reorderedItem.name, tableName });
    newColumns.splice(result.destination.index, 0, reorderedItem);
    updateColumns(newColumns);
  };

  const filteredColumns = columns.filter((c) => c.name.toLowerCase().includes(hiddenSearchQuery.toLowerCase()));

  const selectAllColumns = () => {
    setSelectedShownColumns(filteredColumns.filter(c => !c.isNotRemovable).map((c) => c.name));
  };

  const unselectAllColumns = () => {
    setSelectedShownColumns([]);
  };

  const moveUpSelected = () => {
    const newColumns = Array.from(columns);
    const selectedColumns = newColumns.filter((c) => selectedShownColumns.includes(c.name));
    const remainingColumns = newColumns.filter((c) => !selectedShownColumns.includes(c.name));
    const reorderedColumns = [...selectedColumns, ...remainingColumns];
    updateColumns(reorderedColumns);
  };

  return (
    <div className="h-full flex-1 overflow-y-auto rounded-md border border-slate-200 bg-surface-light">
      <div className="sticky top-0 z-10 border-b border-slate-200 bg-surface-light">
        <Input
          placeholder="Search"
          value={hiddenSearchQuery}
          onInputChange={(e) => setHiddenSearchQuery(e.target.value)}
          rounded="rounded-md"
          className="!w-full p-1"
          height="h-8"
          icon={<MagnifyingGlassIcon width="14" height="14" />}
        />

        <div className="m-2 flex items-center justify-between">
          <div className="flex gap-3">
            {canMoveup && (
              <div className="flex gap-1" onClick={moveUpSelected}>
                <BarsArrowUpIcon width="14" className="cursor-pointer text-slate-500" />
                <span className="cursor-pointer text-sm text-slate-500">Move Up</span>
              </div>
            )}
          </div>
          <div className="flex gap-3">
            <span onClick={selectAllColumns} className="cursor-pointer text-sm text-slate-500">
              Select All
            </span>
            <span onClick={unselectAllColumns} className="cursor-pointer text-sm text-slate-500">
              Clear
            </span>
          </div>
        </div>
      </div>{' '}
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="table-column-selector">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="py-1 text-text-primary">
              {filteredColumns.map((column, index) => {
                const key = `column-selector-${column.name.toLowerCase().replace(/ /g, '-')}`;
                return (
                  <Draggable key={key} draggableId={key} index={index} isDragDisabled={column.isNotRemovable}>
                    {(provided) => (
                      <>
                        <div
                          className={`flex items-center gap-2 px-2 py-2 text-sm ${
                            column.isNotRemovable && 'cursor-not-allowed text-slate-400'
                          }`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}>
                          {!column.isNotRemovable ? (
                            <>
                              <Bars3Icon height={12} width={12} className="text-slate-500" />
                              <Checkbox
                                value={selectedShownColumns.includes(column.name)}
                                setValue={(newValue) => {
                                  setSelectedShownColumns(
                                    newValue
                                      ? [...selectedShownColumns, column.name]
                                      : selectedShownColumns.filter((c) => c !== column.name)
                                  );
                                }}
                              />
                            </>
                          ) : (
                            <div className="w-9"></div>
                          )}
                          {column.name}
                        </div>
                        {index !== columns.length - 1 && <div className="h-[1px] bg-slate-200"></div>}
                      </>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};

const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

const HiddenColumnsList = <T,>({
  columns,
  selectedColumns,
  selectedHiddenColumns,
  setSelectedHiddenColumns,
  searchQuery,
  setSearchQuery
}: {
  columns: TableColumnProps<T>[];
  selectedColumns: TableColumnProps<T>[];
  selectedHiddenColumns: string[];
  setSelectedHiddenColumns: (columns: string[]) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
}) => {

  const unselectAll = () => {
    setSelectedHiddenColumns([]);
  };

  const selectAll = () => {
    setSelectedHiddenColumns(columns.map((c) => c.name));
  };

  const hiddenColumns = columns.filter(
    (c) => !selectedColumns.some((sc) => sc.name === c.name) && c.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const columnGroups = [...new Set(hiddenColumns.map((c) => c.group))];
  return (
    <div className="h-full flex-1 overflow-y-auto rounded-md border border-slate-200 bg-surface-light">
      <div className="sticky top-0 z-10 border-b border-slate-200 bg-surface-light">
        {' '}
        <Input
          placeholder="Search"
          value={searchQuery}
          onInputChange={(e) => setSearchQuery(e.target.value)}
          rounded="rounded-md"
          className="!w-full p-1"
          height="h-8"
          icon={<MagnifyingGlassIcon width="14" height="14" />}
        />
        <div className="m-2 flex gap-3 justify-end">
          <span onClick={selectAll} className="cursor-pointer  text-slate-500 text-sm">Select All</span>
          <span onClick={unselectAll} className="cursor-pointer text-slate-500 text-sm">Clear</span>
        </div>
      </div>
      {columnGroups.map((group) => {
        const columnsInGroup = hiddenColumns
          .filter((c) => c.group === group)
          .sort((a, b) => a.name.localeCompare(b.name));
        return (
          <div key={group}>
            {group && (
              <div key={group} className="mb-1 mt-2 flex items-center gap-2 px-2 text-sm text-slate-400">
                {group}
              </div>
            )}
            {columnsInGroup.map((column, index) => (
              <>
                <div key={column.name} className="flex items-center gap-2 px-2 py-2 text-sm">
                  <Checkbox
                    value={selectedHiddenColumns.includes(column.name)}
                    setValue={(newValue) => {
                      setSelectedHiddenColumns(
                        newValue
                          ? [...selectedHiddenColumns, column.name]
                          : selectedHiddenColumns.filter((c) => c !== column.name)
                      );
                    }}
                  />
                  {column.name}
                </div>
                {index !== columns.length - 1 && <div className="h-[1px] bg-slate-200"></div>}
              </>
            ))}
          </div>
        );
      })}
    </div>
  );
};
