export const filterDuplicates = <T>(array: T[], getKey: (item: T) => string): T[] => {
  const seen = new Set<string>();

  return array.filter((item) => {
    const key = getKey(item);

    if (seen.has(key)) {
      return false;
    } else {
      seen.add(key);
      return true;
    }
  });
};

export const findLast = <T>(array: T[], predicate: (item: T, index: number) => boolean): T | undefined => {
  for (let i = array.length - 1; i >= 0; i--) {
    const item = array[i];

    if (predicate(item, i)) {
      return item;
    }
  }
};

export const findLastIndex = <T>(array: T[], predicate: (item: T, index: number) => boolean): number => {
  for (let i = array.length - 1; i >= 0; i--) {
    const item = array[i];

    if (predicate(item, i)) {
      return i;
    }
  }

  return -1;
};