
export const getContentEditableCaretPosition = (editableDiv: HTMLDivElement) => {
    try {
        const selection = window.getSelection();
        if (!editableDiv || !selection || selection.rangeCount === 0) return null;
        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(editableDiv);
        preCaretRange.setEnd(range.startContainer, range.startOffset);
        const startOffset = preCaretRange.toString().length;
        return startOffset;
    } catch (e) {
        console.error(e);
        return null;
    }
};

export const setContentEdtiabelCaretPosition = (editableDiv: HTMLDivElement, position: number) => {
    try {
        const selection = window.getSelection();
        selection?.removeAllRanges();
        const range = document.createRange();
        let currentOffset = 0;

        const findNodeAndOffset = (node: Node): { node: Node, offset: number } | null => {
            if (node.nodeType === Node.TEXT_NODE) {
                const nodeLength = node.textContent?.length || 0;
                if (position <= currentOffset + nodeLength) {
                    return { node, offset: position - currentOffset };
                }
                currentOffset += nodeLength;
            } else {
                for (let i = 0; i < node.childNodes.length; i++) {
                    const result = findNodeAndOffset(node.childNodes[i]);
                    if (result) return result;
                }
            }
            return null;
        };

        const result = findNodeAndOffset(editableDiv);
        if (result) {
            range.setStart(result.node, result.offset);
            range.collapse(true);
            selection?.addRange(range);
        }
    } catch (e) {
        console.error(e);
    }
};