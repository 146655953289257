import { ChangeEvent, useState } from "react";
import { FormField } from "src/components/form/FormField";
import Input from "src/components/form/Input";
import { GenericIntegration, GenericIntegrationConfiguration } from "src/services/integrations/types";
import Checkbox from "src/components/form/Checkbox";
import { ADVANCED_FIELDS_BY_TYPE } from "./ConnectedSourcesConsts";
import { ChevronDownIcon, ChevronLeftIcon } from "@heroicons/react/24/solid";
import AllowDeny from "src/components/form/AllowDeny";
import Mapping from "src/components/form/Mapping";

type AdvancedBlockType = {
  integration: GenericIntegration,
  setIntegration: (integration: GenericIntegration) => void,
  editMode: boolean
}

const AdvancedBlock = ({ integration, setIntegration, editMode }: AdvancedBlockType) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      <div className="w-full h-[1px] bg-border mb-4"></div>
      <div onClick={() => setIsExpanded(!isExpanded)} className="cursor-pointer flex items-center justify-between text-secondary">
        <span className="font-semibold">Advanced</span>
        <div>
          {isExpanded ? <ChevronDownIcon width="20" height="20" /> : <ChevronLeftIcon width="20" height="20" />}
        </div>
      </div>
      {
        isExpanded && (
          <div className="flex flex-col justify-between mt-4 gap-2">
            {
              ADVANCED_FIELDS_BY_TYPE[integration.integration_type].map(field => {
                const key = `${integration.integration_type}-${field.title}`;
                const value = integration.configuration ? integration.configuration[field.title as keyof GenericIntegrationConfiguration] : '';
                return (
                  <>
                    {field.component === Input && (
                      <FormField
                        key={key}
                        label={field.label}
                        labelClassName='text-secondary mb-1 w-44'
                        helper={field?.optional ? 'Optional' : ''}
                        helperClassName={field?.optional ? 'text-tertiary !text-base' : ''}
                      >
                        <field.component
                          placeholder={editMode && field.sensitive ? '******' : field.placeholder}
                          value={value || ''}
                          type={field?.sensitive ? 'password' : 'text'}
                          onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setIntegration({ ...integration, configuration: { ...integration.configuration, [field.title]: e.target.value } })}
                        />
                      </FormField>
                    )}
                    {field.component === Checkbox && (
                      <field.component
                        value={Boolean(value)}
                        setValue={(value: boolean) =>
                          setIntegration({ ...integration, configuration: { ...integration.configuration, [field.title]: value } })}
                        label={field.label}
                      />
                    )}
                    {field.afterComponent ? <field.afterComponent /> : null}
                  </>
                );
              })
            }
            <MappingContainer integration={integration} setIntegration={setIntegration} />
            <AllowDenyContainer integration={integration} setIntegration={setIntegration} />
          </div>
        )
      }
    </div>
  );
};

type MappingContainerProps = {
  setIntegration: (integration: GenericIntegration) => void,
  integration: GenericIntegration,
}

const MappingContainer = ({ setIntegration, integration }: MappingContainerProps) => {

  let mappingLabel: string;
  let mappingField: keyof GenericIntegrationConfiguration;

  switch (integration.integration_type) {
    case 'looker':
      mappingLabel = "Map your connections";
      mappingField = "connection_mapping" as keyof GenericIntegrationConfiguration;
      break;
    case 'dbt_core':
      mappingLabel = "Map your schema aliases";
      mappingField = "schemas_aliases" as keyof GenericIntegrationConfiguration;
      break;
    default:
      return null;
  }
  
  return (
    <div className="mt-6">
      <span className="text-lg">Mapping</span>
      <div className="flex flex-col justify-between mt-4">
        <Mapping
          label={mappingLabel}
          existingMappings={integration.configuration[mappingField]}
          setMappings={(newMappings) => {
            setIntegration({ ...integration, configuration: { ...integration.configuration, [mappingField]: newMappings } });
          }}
        />
      </div>
    </div>
  );
};

type AllowDenyProps = {
  setIntegration: (integration: GenericIntegration) => void,
  integration: GenericIntegration,
}

const AllowDenyContainer = ({ setIntegration, integration }: AllowDenyProps) => {
  let allowDenyField: keyof GenericIntegrationConfiguration;

  switch (integration.integration_type) {
    case 'tableau':
      allowDenyField = "project_pattern" as keyof GenericIntegrationConfiguration;
      break;
    case 'snowflake':
      allowDenyField = "database_pattern" as keyof GenericIntegrationConfiguration;
      break;
    default:
      return null;
  }

  return (
    <div className="my-4">
      <span className="text-lg">{`${integration.integration_type === "tableau" ? 'Project' : 'Database'} pattern`}</span>
      <div className="flex flex-col justify-between mt-4">
        <AllowDeny
          allowList={integration.configuration[allowDenyField]["allow"]}
          denyList={integration.configuration[allowDenyField]["deny"]}
          setAllowDeny={({ allow, deny }) => {
            setIntegration({ ...integration, configuration: { ...integration.configuration, [allowDenyField]: { allow, deny } } });
          }}
        />
      </div>
    </div>
  );
};


export default AdvancedBlock;
