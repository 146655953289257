import sanitizeHtml from "sanitize-html";
import ContentEditable from 'react-contenteditable';
import { BookOpenIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { notify } from "src/components/Toaster";
import { eunoLinks } from "src/features/eunoLinks";
import { EqlToken, LezerToken } from "src/features/models/discover/EQL/types";
import { parser } from './eql';
import { TreeCursor } from '@lezer/common';
import { replaceAll } from "src/utils/stringsUtils";
import { EunoLogoLoaderIcon, TwoArrowsPointingInIcon, TwoArrowsPointingOutIcon } from "src/assets/images/icons/DelphiIcons";
import { highlightEql } from "src/features/models/discover/EQL/highlightEql";
import { EqlSuggestionsPopover } from "src/features/models/discover/EQL/EqlSuggestionsPopover";
import { getContentEditableCaretPosition, setContentEdtiabelCaretPosition } from "src/utils/domUtils";
import { UpdateDataModelStateProps } from "src/features/models/discover/types";

type EqlEditorProps = {
    eqlInEdit: string;
    setEqlInEdit: (eql: string) => void;
    isLoading: boolean;
    updateDataModelState: (props: UpdateDataModelStateProps) => void;
}

export const EqlEditor = ({ updateDataModelState, eqlInEdit, setEqlInEdit, isLoading }: EqlEditorProps) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef<any>(null);

    const [expandedMode, setExpandedMode] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const tokens = useMemo(() => tokenize(eqlInEdit), [eqlInEdit]);
    const lastCaretPosition = useRef<number | null>(null);
    const loadedFirstTime = useRef(false);
    const eqlInEditRef = useRef(eqlInEdit);

    useEffect(() => {
        if (!loadedFirstTime.current && isLoading) {
            loadedFirstTime.current = true;
        }
    }, [isLoading]);

    const highlightedEqlInEdit = useMemo(() => {
        //Save caret position before highlighting
        const currentCaretPosition = getContentEditableCaretPosition(ref.current);
        lastCaretPosition.current = currentCaretPosition;
        return highlightEql(tokens);
    }, [tokens]);

    // Set caret position after highlighting
    useEffect(() => {
        if (lastCaretPosition.current !== null) {
            setContentEdtiabelCaretPosition(ref.current, lastCaretPosition.current);
        }
    }, [highlightedEqlInEdit]);

    console.log({ eqlInEdit, tokens, highlightedEqlInEdit, tokenTypes: tokens.map(t => t.type, tokens).join() }); //Log to be kept for debugging

    const copy = () => {
        navigator.clipboard.writeText(eqlInEdit);
        notify('EQL copied to clipboard', 'success');
    };

    const onDocumentationClick = () => {
        window.open(eunoLinks.EQL_DOCUMENTATION, '_blank');
    };

    const onSubmit = useCallback(() => {
        updateDataModelState({ eql: eqlInEditRef.current });
    }, [updateDataModelState]);

    const onContentChange = useCallback((evt: React.FormEvent<HTMLDivElement>) => {
        const text = evt.currentTarget.innerText;
        const sanitizedText = sanitize(text);
        setEqlInEdit(sanitizedText);
        eqlInEditRef.current = sanitizedText;
    }, [setEqlInEdit]);

    const onKeyDown = useCallback((evt: React.KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === 'Enter' && (evt.metaKey || evt.ctrlKey)) {
            evt.preventDefault();
            onSubmit();
        }
        setShowSuggestions(true);
    }, [onSubmit]);

    const onFocus = (e: React.FocusEvent<HTMLDivElement>) => {
        ref.current = e.target;
    };

    const focus = () => {
        ref.current?.focus();
    };

    const onSuggestionSelection = (text: string) => {
        const shouldReplaceLastToken = text.toLocaleLowerCase().replace('"', '').startsWith(tokens[tokens.length - 1]?.text.toLocaleLowerCase());
        let newEql: string;
        if (shouldReplaceLastToken) {
            // Remove extra " if the eql already ends with "
            if (text.startsWith('"') && tokens[tokens.length - 2]?.text.match(/["']$/)) {
                text = text.slice(1);
                if (tokens[tokens.length - 2]?.text === "'") {
                    text = text.replace('"', "'");
                }
            }
            newEql = [...tokens.map(t => t.text).slice(0, tokens.length - 1), text].join('');
        }
        else {
            newEql = [...tokens.map(t => t.text), text].join('');
        }
        const sanitizedNewEql = sanitize(newEql);
        setEqlInEdit(sanitizedNewEql);
        eqlInEditRef.current = sanitizedNewEql;
        setContentEdtiabelCaretPosition(ref.current, sanitizedNewEql.length);
        focus();
    };

    return (
        <div className="flex-1 relative">
            <EqlSuggestionsPopover tokens={tokens} isOpen={showSuggestions} onClose={() => setShowSuggestions(false)} onSelect={onSuggestionSelection} />
            <div className={`flex items-start gap-1 bg-white rounded-lg border border-border px-2 py-1.5 break-all overflow-y-auto max-h-28`}>
                <div className="flex-1">
                    <ContentEditable
                        onChange={onContentChange}
                        onFocus={onFocus}
                        onKeyDown={onKeyDown}
                        html={highlightedEqlInEdit}
                        className="outline-none inline-block w-full font-fira-code whitespace-pre-wrap mt-[3px]"
                        placeholder="Start typing your EQL query (e.g database_schema = 'salesforce' AND type = 'looker_dashboard')..."
                        spellCheck={false}
                        ref={ref}
                        style={{ height: expandedMode ? '100px' : 'auto' }}
                    />
                </div>
                <div className="flex gap-1 items-center">
                    {
                        eqlInEdit && (
                            <div onClick={copy} className="ml-auto cursor-pointer text-slate-400 hover:text-slate-500"><DocumentDuplicateIcon width="16" height="16" /></div>
                        )
                    }
                    <div onClick={() => setExpandedMode(!expandedMode)} className="ml-auto cursor-pointer text-text-primary hover:text-slate-500">
                        {expandedMode ? <TwoArrowsPointingInIcon width="16" height="16" /> : <TwoArrowsPointingOutIcon width="16" height="16" />}
                    </div>
                    <div onClick={onDocumentationClick} className="ml-auto cursor-pointer text-text-primary hover:text-slate-500"><BookOpenIcon width="16" height="16" /></div>
                    {
                        isLoading && loadedFirstTime.current ? (
                            <div className="m-1">
                                <EunoLogoLoaderIcon className='text-slate-600' width="16" height="16" />
                            </div>
                        ) : (
                            <div onClick={onSubmit} className="ml-auto cursor-pointer text-white hover:text-slate-50 p-1 rounded-lg bg-lilac-950">
                                <MagnifyingGlassIcon width="16" height="16" />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

const tokenize = (eql: string): EqlToken[] => {
    const tokens: EqlToken[] = [];
    // Parse EQL with Lezer using recursive descent parser
    const printTree = (cursor: TreeCursor, source: string) => {
        do {
            const nodeName = cursor.node.type.name as LezerToken;
            const nodeText = source.slice(cursor.from, cursor.to);
            const pos = `${cursor.from}-${cursor.to}`;
            if (
                nodeName !== 'start' && // Ignore root node
                nodeText !== '' && // Ignore empty nodes
                pos !== tokens[tokens.length - 1]?.pos //For duplicate detection
            ) {
                tokens.push({ text: nodeText, type: nodeName, pos });
            }
            if (cursor.firstChild()) {
                printTree(cursor, source);
                cursor.parent();
            }
        } while (cursor.nextSibling());
    };
    const tree = parser.parse(eql);
    printTree(tree.cursor(), eql);
    return tokens;
};

const sanitize = (text: string) => {
    const entitiesToReplace = [
        { entity: '&gt;', replacement: '>' },
        { entity: '&lt;', replacement: '<' },
        { entity: '&amp;', replacement: '&' },
    ];
    const sanitized = sanitizeHtml(text, { allowedTags: [], allowedAttributes: {} });
    const sanitizedWithEntities = entitiesToReplace.reduce((acc, { entity, replacement }) => replaceAll(acc, entity, replacement), sanitized);
    return sanitizedWithEntities;
};
