interface ToggleProps {
  onChange: () => void;
  value: boolean;
  className?: string;
}

export const Toggle = ({ onChange, className, value }: ToggleProps) => {
  return (
    <div className={`w-11 h-6 flex items-center rounded-2xl cursor-pointer relative transition-all duration-500 ${value ? 'bg-primary' : 'bg-slate-200'} ${className}`} onClick={() => onChange()}>
      <div className={`w-[40%] h-[80%] bg-white rounded-2xl mx-0.5 absolute ${value ? 'left-5' : 'left-0'} transition-all duration-500 `}></div>
    </div>
  );
};
