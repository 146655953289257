import { ChangeEvent, useState } from "react";
import Input from "../../form/Input";
import { FilterComponentProps } from "./types";

type FreeTextFilterProps = FilterComponentProps & {
    placeholder: string;
    label: string;
}

export const FreeTextFilter = ({ value, onChange, placeholder, label }: FreeTextFilterProps) => {
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
    const [focus] = useState(true);

    return (
        <div className="w-60 p-2">
            <div className="mb-2 text-text-primary">{label}</div>
            <Input placeholder={placeholder} value={value || ''} onInputChange={onInputChange} focus={focus} />
        </div>
    );
};