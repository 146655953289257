import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import PageLoader from "src/components/loaders/PageLoader";
import { ResourceSidpaneHeader } from "src/features/models/discover/resourceSidepane/header/ResourceSidepaneHeader";
import { ResourceSidepaneTabs } from "src/features/models/discover/resourceSidepane/ResourceSidepaneTabs";
import { ResourceTab } from "src/features/models/discover/resourceSidepane/types";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetCustomPropertiesQuery, useGetExpandedNodeQuery } from "src/services/nodes/nodes";
import { ResourceSidepaneOverview } from "src/features/models/discover/resourceSidepane/overviewTab/ResourceSidepaneOverview";
import { ResourceSidepaneStickySection } from "src/features/models/discover/resourceSidepane/header/ResourceSidepaneStickySection";
import { IExpandedNode } from "src/features/models/discover/INode";
import { ResourceSidepaneSchemaTab } from "src/features/models/discover/resourceSidepane/schemaTab/ResourceSidepaneSchemaTab";
import { ResourceSidepaneRelationshipsTab } from "src/features/models/discover/resourceSidepane/relationshipsTab/ResourceSidepaneRelationshipsTab";
import { ResourceSidepaneAboutTab } from "src/features/models/discover/resourceSidepane/aboutTab/ResourceSidepaneAboutTab";
import { ResourceSidepaneContentsTab } from "src/features/models/discover/resourceSidepane/contentsTab/ResourceSidepaneContentsTab";
import { ResourceSidepaneUsageTab } from "src/features/models/discover/resourceSidepane/usageTab/ResourceSidepaneUsageTab";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Button from "src/components/button/Button";
import { ButtonTypes } from "src/components/button/types";
import { notify } from "src/components/Toaster";
import { ResourceSidepaneUsedFieldsTab } from "src/features/models/discover/resourceSidepane/usedFieldsTab/ResourceSidepaneUsedFieldsTab";
import { events, trackEvent } from "src/infrastructure/analytics";

type ResourceSidepaneLoader = {
    resourceId: string;
    onClose: () => void;
}

export const ResourceSidepane = ({ resourceId: initialResourceID, onClose }: ResourceSidepaneLoader) => {
    const [isClosing, setIsClosing] = useState<boolean>(false);
    const accountId = useSelector(selectActiveAccountId);
    const [searchParams, setSearchParams] = useSearchParams();
    const resourceId: string = searchParams.get('resourceId') || '';
    const getCustomProperties = useGetCustomPropertiesQuery({ accountId });
    const additionalProperties = getCustomProperties.data?.map(p => p.name) || [];
    const getExpandedResource = useGetExpandedNodeQuery({ accountId, nodeId: resourceId, additionalProperties }, { skip: !resourceId || getCustomProperties.isLoading });
    const [activeTab, setActiveTab] = useState<ResourceTab>(ResourceTab.Overview);
    const expandedResource = getExpandedResource.data;
    const isLoading = getExpandedResource.isFetching;
    const animation = isClosing ? '-right-1/2 duration-200' : 'right-0 duration-200';
    const [historyStack, setHistoryStack] = useState<string[]>([]);

    useEffect(() => {
        if (getExpandedResource.data) {
            trackEvent(events.resourceSidepaneOpened, { type: getExpandedResource.data.type, page: window.location.pathname });
        }
    }, [getExpandedResource.data]);

    useEffect(() => {
        setActiveTab(ResourceTab.Overview);
        if (expandedResource?.name) {
            document.title = `${expandedResource.name} - Euno`;
        }
        return () => {
            document.title = 'Euno';
        };
    }, [expandedResource]);

    useEffect(() => {
        if (getExpandedResource.error) {
            notify('Failed to load resource', 'error');
        }
    }, [getExpandedResource.error]);

    const setActiveTabWithEvent = (tab: ResourceTab) => {
        setActiveTab(tab);
        trackEvent(events.resourceSidepaneNavigatedToTab, { tab });
    };

    const onCloseClick = () => {
        setIsClosing(true);
        setTimeout(() => {
            searchParams.delete('resourceId');
            setSearchParams(searchParams);
            onClose();
            setHistoryStack([]);
        }, 200);
    };

    const setResourceId = useCallback((id: string) => {
        searchParams.set('resourceId', id);
        setSearchParams(searchParams);
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        if (initialResourceID && initialResourceID !== resourceId) {
            setResourceId(initialResourceID);
            setIsClosing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialResourceID]);

    if (!resourceId) {
        return null;
    }

    return (
        <>
            <div className={`${animation} bg-white w-[33vw] min-w-[740px] rounded-l-2xl border overflow-hidden border-border fixed top-0 z-30 h-screen shadow-lg transition-right sidepane`}>
                <div className="flex flex-col h-full">
                    <ResourceSidpaneHeader historyStack={historyStack} setHistoryStack={setHistoryStack} setResourceId={setResourceId} onClose={onCloseClick} resource={expandedResource || null} />
                    {
                        isLoading && <PageLoader />
                    }
                    {
                        !isLoading && expandedResource && (
                            <div className="flex border-t border-border flex-1">
                                <div className="flex flex-col flex-1">
                                    <ResourceSidepaneStickySection setResourceId={setResourceId} resource={expandedResource} />
                                    <div className="flex flex-col flex-1">
                                        <TabContents tab={activeTab} resource={expandedResource} setResourceId={setResourceId} setActiveTab={setActiveTab} />
                                    </div>
                                </div>
                                <ResourceSidepaneTabs resource={expandedResource} activeTab={activeTab} setActiveTab={setActiveTabWithEvent} />
                            </div>
                        )
                    }
                    {
                        !expandedResource && getExpandedResource.isError && (
                            <div className="flex flex-col text-base text-center gap-2 text-tertiary w-fit m-auto">
                                <ExclamationTriangleIcon width={20} height={20} className="mx-auto"/>
                                Problem loading resource
                                <Button type={ButtonTypes.secondary} text="Contact support" onClick={() => window.open('mailto:support@euno.ai')} />
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    );
};

type TabContentsProps = {
    tab: ResourceTab;
    resource: IExpandedNode;
    setResourceId: (id: string) => void;
    setActiveTab: (tab: ResourceTab) => void;
}

const TabContents = ({ tab, resource, setResourceId, setActiveTab }: TabContentsProps) => {
    switch (tab) {
        case ResourceTab.Overview:
            return <ResourceSidepaneOverview setResourceId={setResourceId} resource={resource} setActiveTab={setActiveTab} />;
        case ResourceTab.Schema:
            return <ResourceSidepaneSchemaTab setResourceId={setResourceId} resource={resource} />;
        case ResourceTab.Contents:
            return <ResourceSidepaneContentsTab setResourceId={setResourceId} resource={resource} />;
        case ResourceTab.Relationships:
            return <ResourceSidepaneRelationshipsTab setResourceId={setResourceId} resource={resource} />;
        case ResourceTab.About:
            return <ResourceSidepaneAboutTab resource={resource} />;
        case ResourceTab.Usage:
            return <ResourceSidepaneUsageTab setResourceId={setResourceId} resource={resource} />;
        case ResourceTab.UsedFields:
            return <ResourceSidepaneUsedFieldsTab setResourceId={setResourceId} resource={resource} />;
        default:
            return null;
    }
};