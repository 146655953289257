import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectActiveAccountId } from "../../infrastructure/state/slices/activeAccountSlice";
import { useGetSavedTableViewsQuery, useCreateSavedTableViewMutation, useUpdateSavedTableViewMutation } from "../../services/accounts/accounts";

type UseTableViewProps = {
    tableName: string;
    skip?: boolean;
}

type TableView = {
    id?: number;
    columns?: string[];
    filters?: { key: string, value: string | null }[];
}

export const useTableView = ({ tableName, skip = false }: UseTableViewProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const getSavedTableViewsQuery = useGetSavedTableViewsQuery({ accountId, tableKey: tableName }, { skip });
    const [createSavedTableView] = useCreateSavedTableViewMutation();
    const [updateSavedTableView] = useUpdateSavedTableViewMutation();
    const tableView: TableView | null = useMemo(() => {
        const tableView = getSavedTableViewsQuery.data?.[0];
        if (tableView) {
            const parsedConfig = JSON.parse(tableView.config);
            return { id: tableView.id, columns: parsedConfig.columns, filters: parsedConfig.filters };
        }
        return null;
    }, [getSavedTableViewsQuery.data]);

    const saveTableView = useCallback((savedTable: TableView) => {
        const config = JSON.stringify(savedTable);
        if (savedTable.id) {
            updateSavedTableView({ accountId, viewId: savedTable.id, name: tableName, config, tableKey: tableName });
        } else {
            createSavedTableView({ accountId, tableKey: tableName, name: tableName, config });
        }
    }, [accountId, createSavedTableView, tableName, updateSavedTableView]);

    return {
        saveTableView,
        tableView,
        isLoading: getSavedTableViewsQuery.isLoading
    };
};