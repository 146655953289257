import { Toggle } from "src/components/Toggle";
import { TimeSelector } from "src/components/TimeSelector";
import { WeekDays } from "src/components/WeekDays";
import RadioButton from "src/components/form/RadioButton";
import Select, { Option } from "src/components/form/Select";
import { GenericIntegration, WeekDay } from "src/services/integrations/types";


type ScheduleBlockType = {
  integration: GenericIntegration,
  setIntegration: (integration: GenericIntegration) => void,
}

const defaultWeeklySchedule = {
  time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  repeat_on: [WeekDay.Thu],
  repeat_time: '10:00:00',
};

const defaultHourlySchedule = {
  time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  repeat_period: 2,
};

const ScheduleBlock = ({ integration, setIntegration }: ScheduleBlockType) => {
  const frequency = integration.schedule?.repeat_on ? 'weekly' : 'hourly';
  const repeatOptions = {
    '1': '1 hour',
    '2': '2 hours',
    '4': '4 hours',
    '8': '8 hours',
    '12': '12 hours',
  };
  const blockOpened = !!integration.schedule;
  const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const toggleWeekDay = (day: WeekDay) => {
    const newRepeatOn = new Set(integration.schedule?.repeat_on || defaultWeeklySchedule.repeat_on);
    if (newRepeatOn.has(day)) {
      newRepeatOn.delete(day);
    } else {
      newRepeatOn.add(day);
    }
    setIntegration({ ...integration, schedule: { ...integration.schedule, repeat_on: Array.from(newRepeatOn), time_zone } });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <span className="text-lg">Schedule</span>
        <Toggle
          onChange={() => {
            if (blockOpened) {
              setIntegration({ ...integration, schedule: undefined });
            }
            else {
              setIntegration({ ...integration, schedule: defaultWeeklySchedule });
            }
          }}
          value={blockOpened}
        />
      </div>
      {blockOpened && (
        <div className='mt-6'>
          <RadioButton
            value={frequency === 'weekly'}
            setValue={() => setIntegration({ ...integration, schedule: { ...defaultWeeklySchedule } })}
            label={'Weekly'}
          />
          {frequency === 'weekly' && (
            <div className='mt-2.5 flex items-center'>
              <span className='mr-6 text-tertiary'>Repeat on</span>
              <WeekDays
                selected={integration.schedule?.repeat_on || defaultWeeklySchedule.repeat_on}
                onChange={toggleWeekDay}
              />
              <span className='ml-6 mr-2.5 text-tertiary'>at</span>
              <TimeSelector
                value={integration.schedule?.repeat_time || defaultWeeklySchedule.repeat_time}
                onChange={(time) => setIntegration({ ...integration, schedule: { ...integration.schedule, repeat_time: time, time_zone } })}
              />
            </div>
          )}
          <RadioButton
            value={frequency === 'hourly'}
            setValue={() => setIntegration({ ...integration, schedule: { ...defaultHourlySchedule } })}
            label={'Hourly'}
            className='mt-4'
          />
          {frequency === 'hourly' && (
            <div className='mt-2.5 flex items-center'>
              <span className='mr-2.5 text-tertiary'>Repeat every</span>
              <Select
                options={Object.entries(repeatOptions).map(([value, label]) => ({ value, label }))}
                value={`${integration.schedule?.repeat_period || defaultHourlySchedule.repeat_period}`}
                onChange={(value) => setIntegration({ ...integration, schedule: { ...integration.schedule, repeat_period: ((value as Option).value as number), time_zone } })}
                className='border'
                isSearchable={false}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ScheduleBlock;
