import { BoltIcon, BookOpenIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronDownIcon, CodeBracketIcon, EllipsisHorizontalIcon, LinkIcon, ViewfinderCircleIcon } from "@heroicons/react/24/outline";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useClickAway } from "react-use";
import { DbtDottedSquareIcon, SparklesLeftArrowIcon } from "src/assets/images/icons/DelphiIcons";
import CreateChangeSidepane from "src/features/evolution/createChangeSidepane/CreateChangeSidepane";
import { generateCreateAggregationTableChangeFromMetric } from "src/features/evolution/generateCreateAggregationTableChangeFromMetric";
import { Pat } from "src/features/evolution/IChange";
import { IExpandedNode, NODES_TO_HIDE_FROM_DAG, NodeType } from "src/features/models/discover/INode";
import CodeModal from "src/features/models/discover/resourceSidepane/DiscoverCodeModal";
import { events, trackEvent } from "src/infrastructure/analytics";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useGetPatQuery } from "src/services/changes/changes";
import { useGetProjectQuery } from "src/services/projects/projects";

export const ResourceSidepaneButtons = ({ resource }: { resource: IExpandedNode }) => {
    return (
        <div className="flex gap-4">
            <AutomationsButton resource={resource} />
            <CodeButton resource={resource} />
            <MoreButton resource={resource} />
        </div>
    );
};

const AutomationsButton = ({ resource }: { resource: IExpandedNode }) => {
    const [showPatsOptions, setShowPatsOptions] = useState<boolean>(false);
    const [activePat, setActivePat] = useState<Pat | null>(null);
    const [pregAggPat, setPregAggPat] = useState<Pat | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    useClickAway(ref, () => setShowPatsOptions(false));
    const accountId = useSelector(selectActiveAccountId);
    const getPatQuery = useGetPatQuery({ accountId, uri: resource.id }, { skip: !resource.hasProposals });
    const projectId = resource.eunoProjectId || getPatQuery.data?.projectId;
    const getProject = useGetProjectQuery(projectId || 0, { skip: !projectId });
    const { hideShiftLeft } = useFlags();
    useEffect(() => {
        if (resource.type === NodeType.Metric && resource.eunoProjectId) {
            generateCreateAggregationTableChangeFromMetric(resource, resource.eunoProjectId, accountId).then(setPregAggPat);
        }
    }, [resource, accountId]);
    const shiftLeftPat = getPatQuery.data;

    useEffect(() => {
        setShowPatsOptions(false);
    }, [activePat]);

    const onLearnMoreClick = () => {
        window.open('https://docs.euno.ai/automations', '_blank');
    };

    if ((!shiftLeftPat && !pregAggPat) || !getProject.data?.userAllowedToPromotePats || hideShiftLeft) {
        return null;
    }

    return (
        <div className="relative" ref={ref}>
            <ButtonWrapper text="Automations" onClick={() => setShowPatsOptions(!showPatsOptions)} width={65} className="bg-gradient-to-tr to-[#FFF7ED] from-[#FAF5FF]">
                <div className="text-primary flex items-center -mr-2">
                    <BoltIcon width={16} height={16} />
                    <ChevronDownIcon width={8} height={8} />
                </div>
            </ButtonWrapper>
            {
                showPatsOptions && (
                    <div className="z-20 absolute top-8 pt-3 right-0 shadow-xl bg-slate-50 p-1 rounded-lg border border-border">
                        <div className="flex gap-1 justify-center">
                            {
                                shiftLeftPat && (
                                    <div onClick={() => setActivePat(shiftLeftPat)} className="cursor-pointer w-40 items-center text-text-primary text-center rounded border border-border bg-gradient-to-b from-lilac-50 hover:from-lilac-100 to-50% p-4 flex flex-col gap-2">
                                        <SparklesLeftArrowIcon width={24} height={24} />
                                        <span className="font-semibold">Shift left</span>
                                        <span className="text-sm">Create a model change proposal to move this item to dbt</span>
                                    </div>
                                )
                            }
                            {
                                pregAggPat && (
                                    <div onClick={() => setActivePat(pregAggPat)} className="cursor-pointer w-40 items-center text-text-primary text-center rounded border border-border bg-gradient-to-b from-orange-50 hover:from-orange-100 to-50% p-4 flex flex-col gap-2">
                                        <DbtDottedSquareIcon width={24} height={24} className="text-orange-800" />
                                        <span className="font-semibold">Add pre-aggregate</span>
                                        <span className="text-sm">Generate pre-aggregate model based on metric</span>
                                    </div>
                                )
                            }
                        </div>
                        <div onClick={onLearnMoreClick} className="flex px-2 py-1 text-sm items-center whitespace-nowrap gap-1 text-slate-400 hover:text-slate-500 cursor-pointer"><BookOpenIcon width={16} height={16} /> Learn more about Automations</div>
                    </div>
                )
            }
            {
                activePat && (
                    <CreateChangeSidepane isOpen={!!activePat} onClose={() => setActivePat(null)} pat={activePat} />
                )
            }
        </div >
    );
};

const CodeButton = ({ resource }: { resource: IExpandedNode }) => {
    const [showCode, setShowCode] = useState<boolean>(false);
    const setShowCodeTrue = () => {
        setShowCode(true);
        trackEvent(events.resourceSidepaneViewCodeClick, { resourceType: resource.type });
    };

    if (!resource.withCode) {
        return null;
    }
    return (
        <>
            <ButtonWrapper text="View code" onClick={setShowCodeTrue} width={65}>
                <CodeBracketIcon width={16} height={16} />
            </ButtonWrapper>
            <CodeModal isOpen={showCode} onClose={() => setShowCode(false)} parentNode={resource} />
        </>
    );
};

const MoreButton = ({ resource }: { resource: IExpandedNode }) => {
    const [showMore, setShowMore] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    useClickAway(ref, () => setShowMore(false));
    const items = [];

    if (!NODES_TO_HIDE_FROM_DAG.includes(resource.type)) {
        items.push(
            {
                name: 'Isolate in DAG',
                icon: <ViewfinderCircleIcon width="14" height="14" />,
                onClick: () => window.open(`/data-model?view=graph&URI=${resource.id}`, '_blank')
            },
            {
                name: 'Show downstream dependents',
                icon: <ChevronDoubleRightIcon width="14" height="14" />,
                onClick: () => window.open(`/data-model?view=table&Downstream dependents=${resource.id}`, '_blank')
            },
            {
                name: 'Show upstream dependencies',
                icon: <ChevronDoubleLeftIcon width="14" height="14" />,
                onClick: () => window.open(`/data-model?view=table&Upstream dependencies=${resource.id}`, '_blank')
            }
        );
    }

    // Add external links to the items
    if (resource.externalLinks?.length > 0) {
        resource.externalLinks.forEach(link => {
            items.push({
                name: link.label,
                icon: <LinkIcon width="16" height="16" />,
                onClick: () => window.open(link.url, '_blank')
            });
        });
    }

    if (items.length === 0) {
        return null;
    }

    return (
        <div className="relative" ref={ref}>
            <ButtonWrapper text="Actions" onClick={() => setShowMore(!showMore)} width={35}>
                <EllipsisHorizontalIcon width={16} height={16} />
            </ButtonWrapper>
            {
                showMore && (
                    <div className="text-text-primary z-20 absolute top-8 right-0 shadow-xl bg-slate-50 p-1 rounded-lg border border-border">
                        {
                            items.map(item => (
                                <div key={item.name} onClick={item.onClick} className="flex gap-1 items-center px-2 py-1 text-sm cursor-pointer hover:bg-slate-100">
                                    <div>{item.icon}</div>
                                    <span className="whitespace-nowrap">{item.name}</span>
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

type ButtonWrapperProps = {
    children: React.ReactNode;
    onClick: () => void;
    text: string;
    width: number;
    className?: string;
};
const ButtonWrapper = ({ children, onClick, text, width, className = '' }: ButtonWrapperProps) => {
    return (
        <div className="flex flex-col gap-1 items-center text-secondary relative">
            <div onClick={onClick} style={{ width }} className={`border border-border rounded py-1.5 h-fit bg-slate-50 hover:bg-slate-100 text-secondary cursor-pointer ${className}`}>
                <div className="flex w-fit mx-auto items-center">
                    {children}
                </div>
            </div>
            <span className="text-center font-medium">{text}</span>
        </div>
    );
};