import { Tooltip } from "react-tooltip";

interface Props {
    text: string;
    maxChars: number;
}

export const TextWithEllipsisAndTooltip = ({ text, maxChars }: Props) => {
    const outputText = text ?? '';
    const showTooltip = outputText?.length > maxChars;
    const textWithEllipsis = outputText?.length > maxChars ? `${outputText.substring(0, maxChars)}...` : outputText;
    const id = Math.random().toString(36).substring(7);
    return (
        <>
            <div data-tag="allowRowEvents" data-tooltip-id={id} data-tooltip-content={outputText}>{textWithEllipsis}</div>
            {
                showTooltip && (
                    <Tooltip id={id} style={{zIndex: 100}}/>
                )
            }
        </>
    );
};
