import { ChangeEvent } from "react";
import { FormField } from "src/components/form/FormField";
import Input from "src/components/form/Input";
import {
  GenericIntegration,
  GenericIntegrationConfiguration,
} from "src/services/integrations/types";
import Checkbox from "src/components/form/Checkbox";
import { FIELDS_BY_TYPE } from "./ConnectedSourcesConsts";


type ConfigurationDetailsBlockType = {
  integration: GenericIntegration,
  setIntegration: (integration: GenericIntegration) => void,
  editMode: boolean
}

export const ConfigurationDetailsBlock = ({ integration, setIntegration, editMode }: ConfigurationDetailsBlockType) => {

  return (
    <div>
      <span className="text-lg">Configuration details</span>
      <div className="flex flex-col justify-between mt-4 gap-2">
        {
          FIELDS_BY_TYPE[integration.integration_type].map(field => {
            const key = `${integration.integration_type}-${field.title}`;
            const value = integration.configuration ? integration.configuration[field.title as keyof GenericIntegrationConfiguration] : '';
            return (
              <FormField
                key={key}
                label={field.component === Input ? field.label : ""}
                helper={field?.optional ? 'Optional' : ''}
                labelClassName="w-44"
              >
                {field.component === Input && (
                  <field.component
                    placeholder={editMode && field.sensitive ? '******' : field.placeholder}
                    value={value}
                    type={field?.sensitive ? 'password' : 'text'}
                    onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setIntegration({ ...integration, configuration: { ...integration.configuration, [field.title]: e.target.value } })}
                  />
                )}
                {field.component === Checkbox && (
                  <field.component
                    value={Boolean(value)}
                    setValue={(value: boolean) =>
                      setIntegration({ ...integration, configuration: { ...integration.configuration, [field.title]: value } })}
                    label={field.label}
                  />
                )}
              </FormField>
            );
          })
        }
      </div>
    </div>
  );
};