export const AllowResourcesWithNoCatalogEntryInfo = () => {
  return (
    <div className="font-normal text-tertiary text-s mb-4 pl-5">
      <p className="mb-4">
      By default, Euno only observes dbt resources (e.g., models, sources, snapshots, and seeds) that have a corresponding entry in the <span className="text-lilac-700">catalog.json</span> file. Checking this box will expand the scope to include all resources listed in the <span className="text-lilac-700">manifest.json</span> file, even if they do not appear in the <span className="text-lilac-700">catalog.json</span> file.
      </p>
      <p>
      <span className="font-bold">Note:</span> Resources without a matching entry in the <span className="text-lilac-700">catalog.json</span> file will not have schema information available, as this data is exclusively pulled from the catalog.
      By default, the integration processes only builds with a complete, error-free <span className="text-lilac-700">catalog.json</span>.
      </p>
    </div>
  );
};

