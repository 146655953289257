import IBuild from "../../builds/IBuild";
import { NodeType } from "../../models/discover/INode";
import { NodeIcon } from "../../models/discover/NodeIcon";

export const ProjectBuildStats = ({ build }: { build: IBuild }) => {
    return (
        <div className="flex gap-2 text-sm">
            <StatContainer name="dbt Sources" value={build.sourcesCount} icon={<NodeIcon iconSize={14} type={NodeType.DataSource} />} />
            <StatContainer name="Data models" value={build.modelsCount} icon={<NodeIcon iconSize={14} type={NodeType.DataModel} greyVersion={true} />} />
            {/* <StatContainer name="Related metrics" value={build.metricsCount} icon={<NodeIcon iconSize={14} type={NodeType.DataModel} subnodeType={SubnodeType.Metric} />} /> */}
        </div>
    );
};

const StatContainer = ({ name, value, icon }: { name: string, value: number, icon: JSX.Element }) => {
    return (
        <div className="rounded-sm bg-slate-50 p-2 text-center w-36">
            <div className="w-fit mx-auto flex gap-1 items-center text-tertiary">
                {icon}
                <div className="text-base text-secondary font-medium">{value}</div>
            </div>
            <div className="text-secondary">{name}</div>
        </div>
    );
};
