import { store } from "../../../../../infrastructure/state/store";
import { nodesApi } from "../../../../../services/nodes/nodes";
import { ISuperficialNode, NODES_TO_HIDE_FROM_DAG } from "../../INode";
import { MAX_RESOURCES_IN_DAG } from "./DAGConfiguration";
import { ExpandedNode, IDAGNode } from "./types";

type GetNodesForDAGProps = {
    accountId: number;
    eql: string;
}

export const getDAGNodes = async ({ accountId, eql }: GetNodesForDAGProps): Promise<{ newNodes: IDAGNode[], filteredNodes: ISuperficialNode[] }> => {
    const originalNodes = await getOriginalNodes({ accountId, eql });
    const { filteredNodes, nodes } = originalNodes.reduce((agg: { filteredNodes: ISuperficialNode[], nodes: ISuperficialNode[] }, node: ISuperficialNode) => {
        if (NODES_TO_HIDE_FROM_DAG.includes(node.type)) {
            return { filteredNodes: [...agg.filteredNodes, node], nodes: agg.nodes };
        } else {
            return { filteredNodes: agg.filteredNodes, nodes: [...agg.nodes, node] };
        }
    }, { filteredNodes: [], nodes: [] });
    const distinctNodes = nodes.filter((node, index, self) => self.findIndex(n => n.id === node.id) === index);
    return { newNodes: distinctNodes.map(n => ({ ...n, isConnectedNode: false })), filteredNodes };
};

export const getConnectedNodes = async ({ accountId, expandedNodes, maxResources }: { accountId: number, expandedNodes: ExpandedNode[], maxResources: number }) => {
    const resourcesPerExpansion = await Promise.all(expandedNodes.map(async (expandedNode) => {
        const directionEql = expandedNode.direction === 'upstream' ? 'downstream' : 'upstream';
        const eql = `has ${directionEql}(uri='${expandedNode.nodeId}', ${expandedNode.depth || 100})`;
        const { data } = await store.dispatch(nodesApi.endpoints.getDataModelResources.initiate({
            accountId,
            eql,
            page: 1,
            pageSize: maxResources + 1
        }));
        return {
            resources: data?.items || [],
            total: data?.total || 0,
            direction: expandedNode.direction,
            depth: expandedNode.depth,
            originalUri: expandedNode.nodeId
        };
    }));
    return resourcesPerExpansion;
};

type GetOriginalNodesProps = {
    accountId: number;
    eql: string;
}

const getOriginalNodes = async ({ accountId, eql }: GetOriginalNodesProps) => {
    const { data, error } = await store.dispatch(nodesApi.endpoints.getDataModelResources.initiate({
        accountId,
        eql,
        page: 1,
        pageSize: MAX_RESOURCES_IN_DAG + 1,
        withSubResources: true
    }));
    if (error) {
        throw error;
    }
    return (data?.items || []);
};
