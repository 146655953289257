import { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonTypes } from "src/components/button/types";
import DropdownMenu from "src/components/DropdownMenu/DropdownMenu";
import Input from "src/components/form/Input";
import Modal from "src/components/Modal/Modal";
import { notify } from "src/components/Toaster";
import { DATA_MODEL_TABLE_KEY, DataModelTableView } from "src/features/models/discover/toolbar/tabs/types";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { useDeleteSavedTableViewMutation, useUpdateSavedTableViewMutation, useCreateSavedTableViewMutation } from "src/services/accounts/accounts";

type DataModelTabMenuProps = {
    tab: DataModelTableView;
}

export const DataModelTabMenu = ({ tab }: DataModelTabMenuProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const [deleteTab, { isLoading: isLoadingDelete }] = useDeleteSavedTableViewMutation();
    const [createTab, { isLoading: isLoadingCreate }] = useCreateSavedTableViewMutation();
    const [showRenameModal, setShowRenameModal] = useState(false);

    const onDuplicate = async () => {
        try {
            await createTab({ accountId, tableKey: DATA_MODEL_TABLE_KEY, name: `copy of (${tab.name})`, config: JSON.stringify(tab.config) }).unwrap();
            notify('Tab duplicated', 'success');
        } catch (error) {
            console.error(error);
            notify('Failed to duplicate tab', 'error');
        }
    };

    const onDelete = async () => {
        try {
            await deleteTab({ accountId, tableKey: DATA_MODEL_TABLE_KEY, viewId: tab.id }).unwrap();
            notify('Tab deleted', 'success');
        } catch (error) {
            console.error(error);
            notify('Failed to delete tab', 'error');
        }
    };

    return (
        <>
            <DropdownMenu
            className="z-40"
                items={[
                    { name: 'Rename', onClick: () => setShowRenameModal(true) },
                    { name: isLoadingCreate ? 'Duplicating..' : 'Duplicate', onClick: onDuplicate },
                    { name: isLoadingDelete ? 'Deleting..' : 'Delete', onClick: onDelete, className: '!text-danger' },
                ]}
            />
            <RenameModal isOpen={showRenameModal} onClose={() => setShowRenameModal(false)} tab={tab} />
        </>
    );
};

type RenameModalProps = {
    isOpen: boolean;
    onClose: () => void;
    tab: DataModelTableView;
}

const RenameModal = ({ isOpen, onClose, tab }: RenameModalProps) => {
    const [name, setName] = useState(tab.name);
    const [saveTab, { isLoading: isLoadingSave }] = useUpdateSavedTableViewMutation();
    const accountId = useSelector(selectActiveAccountId);

    const onSave = async () => {
        if (!name) return;
        try {
            await saveTab({ accountId, tableKey: DATA_MODEL_TABLE_KEY, viewId: tab.id, name }).unwrap();
            notify('Filter renamed', 'success');
            onClose();
        } catch (error) {
            console.error(error);
            notify('Failed to rename filter', 'error');
        }
    };

    return (
        <Modal
            title="Change Filter name"
            isOpen={isOpen}
            onClose={onClose}
            buttons={[
                { text: 'Cancel', onClick: onClose, type: ButtonTypes.secondary },
                { text: 'Rename', onClick: onSave, type: ButtonTypes.primary, isLoading: isLoadingSave, isDisabled: !name }
            ]}
        >
            <Input onEnter={() => onSave()} placeholder="Enter new name" value={name} onInputChange={e => setName(e.target.value)} />
        </Modal>
    );
};