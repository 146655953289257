import { ChevronDownIcon, MagnifyingGlassIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useClickAway } from "react-use";
import Input from "src/components/form/Input";
import { TextWithEllipsisAndTooltip } from "src/components/TextWithEllipsisAndTooltip";
import { allFilters } from "src/features/models/discover/toolbar/filters/DiscoverFilterList";
import { Filter } from "src/features/models/discover/toolbar/filters/Types";
import { DataModelTabMenu } from "src/features/models/discover/toolbar/tabs/DataModelTabMenu";
import { DATA_MODEL_TABLE_KEY, DataModelTableView } from "src/features/models/discover/toolbar/tabs/types";
import { UpdateDataModelStateProps } from "src/features/models/discover/types";
import { selectActiveAccountId } from "src/infrastructure/state/slices/activeAccountSlice";
import { selectIsModalOpen } from "src/infrastructure/state/slices/isModalOpenSlice";
import showSubResouresInDataModelTableSlice from "src/infrastructure/state/slices/showSubResouresInDataModelTableSlice";
import { useGetSavedTableViewsQuery } from "src/services/accounts/accounts";
import { discoverTableColumns } from "../../view/table/discoverTableColumns";

type DataModelTabsProps = {
    filters: Filter[];
    selectedTabId: number | null;
    setSelectedTabId: (tab: number | null) => void;
    updateDataModelState: (props: UpdateDataModelStateProps) => void;
}

export const DataModelTabs = ({ filters, selectedTabId, setSelectedTabId, updateDataModelState }: DataModelTabsProps) => {
    const accountId = useSelector(selectActiveAccountId);
    const getTabs = useGetSavedTableViewsQuery({ accountId, tableKey: DATA_MODEL_TABLE_KEY });
    const tabs = useMemo(() => (getTabs.data || []).map(tab => ({ ...tab, config: JSON.parse(tab.config) } as DataModelTableView)), [getTabs.data]);
    const dispatch = useDispatch();
    const lastAppliedTab = useRef<number | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showTabSelector, setShowTabSelector] = useState(false);
    const [searchTabQuery, setSearchTabQuery] = useState('');
    const tabSelectorRef = useRef<HTMLDivElement>(null);
    const isModalOpen = useSelector(selectIsModalOpen);
    useClickAway(tabSelectorRef, () => !isModalOpen && setShowTabSelector(false));
    const selectedTab = tabs.find(t => t.id === selectedTabId) || null;

    const cleanTabFilters = () => {
        searchParams.delete('eql');
        for (const filter of allFilters) {
            searchParams.delete(filter.name);
        }
        setSearchParams(searchParams);
    };

    const selectTab = (tab: DataModelTableView) => {
        setSelectedTabId(tab.id);
        setShowTabSelector(false);
    };

    const createEmptyTab = () => {
        cleanTabFilters();
        setSelectedTabId(null);
        setShowTabSelector(false);
        updateDataModelState({ columns: discoverTableColumns.filter(c => c.isDefault).map(c => c.name) });
    };

    //Apply tab eql/filters after selection
    useEffect(() => {
        if (selectedTab && selectedTab.id !== lastAppliedTab.current) {
            console.log('Applying tab', selectedTab);
            lastAppliedTab.current = selectedTab.id;
            if (selectedTab.config.filterMode === 'eql' && selectedTab.config.eql) {
                updateDataModelState({ eql: selectedTab.config.eql });
            }
            else if (selectedTab.config.filterMode === 'basic') {
                dispatch(showSubResouresInDataModelTableSlice.actions.setShowSubResourcesInDataModelTable(selectedTab.config.showSubResources || false));
                const appliedFilters: Filter[] = filters.map(f => ({ ...f, value: selectedTab.config.filters?.find(fv => fv.name === f.name)?.value || null }));
                const columns = selectedTab.config.columns || undefined;
                updateDataModelState({ filters: appliedFilters, columns });
            }
        }
    }, [dispatch, filters, searchParams, selectedTab, setSearchParams, tabs, updateDataModelState]);

    return (
        <div className="relative w-fit mt-2 ml-1" ref={tabSelectorRef}>
            <div className="flex gap-2">
                <div className="flex gap-1 items-center tracking-wide text-slate-700 font-semibold cursor-pointer w-fit px-2" onClick={() => setShowTabSelector(!showTabSelector)}>
                    <div><TextWithEllipsisAndTooltip text={selectedTab?.name || 'Untitled filter'} maxChars={35}/></div>
                    <div><ChevronDownIcon width="16" height="16" className="text-slate-500" /></div>
                </div>
            </div>
            {
                showTabSelector && (
                    <div className={`absolute min-w-[300px] rounded-md top-10 z-20 left-0 bg-white border shadow p-2 flex flex-col gap-2 border-border`}>
                        {
                            tabs.length > 0 && (
                                <>
                                    <Input icon={<MagnifyingGlassIcon width="14" height="14" className="text-slate-400" />} placeholder="Search saved filters" value={searchTabQuery} onInputChange={e => setSearchTabQuery(e.target.value)} />
                                    <div className="rounded-lg border border-border overflow-visible">
                                        {
                                            tabs.filter(t => t.name.toLowerCase().includes(searchTabQuery.toLowerCase())).map(tab => (
                                                <div key={tab.id} className="p-2 flex items-center cursor-pointer hover:bg-slate-100 border-b border-border">
                                                    <div className="flex-1" onClick={() => selectTab(tab)}>{tab.name}</div>
                                                    <DataModelTabMenu tab={tab} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            )
                        }
                        <div className="w-full max-w-md flex items-center justify-center mx-auto gap-1 py-2 text-primary cursor-pointer hover:bg-slate-100 hover:rounded-md" onClick={createEmptyTab}><PlusIcon width={16} height="16" />Create new filter</div>
                    </div>
                )
            }
        </div>
    );
};
