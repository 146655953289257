import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getSessionToken } from '@descope/react-sdk';
import { generateRandomHexString } from '../utils/stringsUtils';
import config from '../config';
import { TRACE_HEADER } from './consts';
import { Environment, getCurrentEnv } from '../infrastructure/env';

const baseQuery = fetchBaseQuery({
  baseUrl: `${config.apiUrl}`,
  prepareHeaders: (headers) => {
    // If we have a token available, we use it to authenticate with the API
    if (getCurrentEnv() === Environment.Testing) {
      const testUser = localStorage.getItem('TEST_USER');
      if (!testUser) {
        throw new Error('No test user found');
      }
      headers.set('Authorization', testUser);
    }
    else {
      const token = getSessionToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
    }

    // APM Tracing
    headers.set(TRACE_HEADER, `${generateRandomHexString()};o=1`);
    return headers;
  }
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const newUrl = '/login';
    window.location.href = newUrl;
  }
  return result;
};

const api = createApi({
  keepUnusedDataFor: 30,
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Accounts',
    'Folders',
    'Projects',
    'Users',
    'Branches',
    'Builds',
    'Stakeholders',
    'Changes',
    'Eula',
    'Integrations',
    'SavedTableViews',
    'Operations',
    'Actions'
  ],
  endpoints: () => ({})
});

export const extractErrorMessage = (e: unknown): { type: BackendErrorType, message: string } => {
  let type = BackendErrorType.Unknown;
  let message = 'Unknown message';
  if (typeof e === 'string') {
    message = e;
  }
  else if (typeof e === 'object' && e) {
    if ('error' in e) {
      message = e.error as string;
    }
    else if ('message' in e) {
      message = e.message as string;
    }
    else if ('data' in e && typeof e.data === 'object' && e.data !== null && 'detail' in e.data) {
      const error = Array.isArray(e.data.detail) ? e.data.detail[0] : e.data.detail;
      if (typeof error === 'string') {
        message = error;
      } else if (typeof error === 'object' && error !== null) {
        //EQL error parsing
        if ('error' in error && 'column' in error && 'line' in error) {
          message = `${error.error} at ${error.line}:${error.column}`;
        }
        if ('message' in error) {
          message = error.message as string;
        }
        if ('msg' in error) {
          message = error.msg as string;
        }
        if ('error_type' in error && Object.values(BackendErrorType).includes(error.error_type as BackendErrorType)) {
          type = error.error_type as BackendErrorType;
        }
      }
    }
  }
  return { type, message };
};

export enum BackendErrorType {
  MetricFlow = 'metricflow_error',
  Unknown = 'unknown',
}

export default api;
