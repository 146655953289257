import { MagnifyingGlassIcon, CheckIcon, ChevronLeftIcon, CodeBracketIcon } from "@heroicons/react/24/solid";
import { useState, useMemo } from "react";
import { DiscoverFilterProps } from "./Types";
import Input from "../../../../../components/form/Input";

export const MetaFilter = ({ value, onChange, filterOptions }: DiscoverFilterProps) => {
    const parsedMetaValue = useMemo(() => parseMetaValue(value), [value]);
    const metaOptions = stringifiedObjectsToKeyValuePair(filterOptions?.meta || []);
    const keys = [...new Set(metaOptions.map(keyValue => keyValue.split('=')[0]))];
    const [selectedKey, setSelectedKey] = useState<string | null>(null);

    return (
        <div className="p-2">
            {selectedKey ? (
                <SelectValueScreen
                    onChange={onChange}
                    selectedKey={selectedKey}
                    parsedMetaValue={parsedMetaValue}
                    metaOptions={metaOptions}
                    setSelectedKey={setSelectedKey}
                />
            ) : (
                <SelectKeyScreen
                    onChange={onChange}
                    keys={keys}
                    parsedMetaValue={parsedMetaValue}
                    setSelectedKey={setSelectedKey}
                />
            )}
        </div>
    );
};

interface SelectKeyScreenProps {
    keys: string[];
    parsedMetaValue: { key: string; value: string[] }[];
    setSelectedKey: (key: string) => void;
    onChange: (value: string | null) => void;
}

const SelectKeyScreen = ({ keys, parsedMetaValue, setSelectedKey, onChange }: SelectKeyScreenProps) => {
    const [searchMeta, setSearchMeta] = useState<string>('');
    const filteredKeys = useMemo(
        () => keys.filter((key) => key.toLowerCase().includes(searchMeta.toLowerCase())),
        [searchMeta, keys]
    );
    
    const clearAll = () => {
        onChange(null);
    };
    
    return (
        <>
            <Input
                icon={<MagnifyingGlassIcon width={14} height={14} className="text-slate-400" />}
                placeholder="Search meta keys"
                height="h-8"
                background="bg-slate-100"
                value={searchMeta}
                onInputChange={(e) => setSearchMeta(e.target.value)}
                />
            <div onClick={clearAll} className="text-tertiary my-2 cursor-pointer w-fit ml-auto text-sm">Clear</div>
            <div className="h-[300px] overflow-auto">
                {filteredKeys.map((key) => {
                    const numberOfSelectedValues = parsedMetaValue.filter((meta) => meta.key === key).length;
                    return (
                        <div
                            key={key}
                            className={`flex cursor-pointer items-center justify-between p-2 hover:bg-slate-100 w-full`}
                            onClick={() => setSelectedKey(key)}>
                            <div className="flex items-center gap-2">
                                <CodeBracketIcon width="18" height="18" className="text-slate-400" />

                                <span>{key}</span>
                            </div>
                            {numberOfSelectedValues > 0 && <div className="text-primary font-semibold text-sm">{numberOfSelectedValues}</div>}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

interface SelectValueScreenProps {
    selectedKey: string;
    parsedMetaValue: { key: string; value: string[] }[];
    onChange: (value: string) => void;
    metaOptions: string[];
    setSelectedKey: (key: string | null) => void;
}

const SelectValueScreen = ({
    selectedKey,
    parsedMetaValue,
    onChange,
    metaOptions,
    setSelectedKey
}: SelectValueScreenProps) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const valueOptions = [...new Set(metaOptions.filter((meta) => meta.startsWith(`${selectedKey}=`)).map((meta) => meta.split('=')[1]))];
    const selectedValues = parsedMetaValue.find((meta) => meta.key === selectedKey)?.value || [];

    const onClick = (option: string, isSelected: boolean) => {
        const newValue = isSelected ? selectedValues.filter((v) => v !== option) : [...selectedValues, option];
        const newMetaValue = parsedMetaValue
            .filter((meta) => meta.key !== selectedKey)
            .concat([{ key: selectedKey, value: newValue }])
            .filter((meta) => meta.value.length > 0)
            .map((meta) => `${meta.key}=${meta.value.join(',')}`)
            .join(';');
        onChange(newMetaValue);
    };

    const onClear = () => {
        const newMetaValue = parsedMetaValue
            .filter((meta) => meta.key !== selectedKey)
            .map((meta) => `${meta.key}=${meta.value.join(',')}`)
            .join(';');
        onChange(newMetaValue);
    };

    const selectAll = () => {
        const newMetaValue = parsedMetaValue
            .filter((meta) => meta.key !== selectedKey)
            .concat([{ key: selectedKey, value: valueOptions }])
            .map((meta) => `${meta.key}=${meta.value.join(',')}`)
            .join(';');
        onChange(newMetaValue);
    };

    return (
        <>
            <div>
                <Input
                    icon={<MagnifyingGlassIcon width={14} height={14} className="text-slate-400" />}
                    placeholder={`Search values of ${selectedKey}`}
                    height="h-8"
                    background="bg-slate-100"
                    value={searchValue}
                    onInputChange={(e) => setSearchValue(e.target.value)}
                />
            </div>
            <div className="flex items-center text-sm my-2 text-tertiary gap-2">
                <div className="items-center flex gap-1 cursor-pointer" onClick={() => setSelectedKey(null)}>
                    <ChevronLeftIcon width="12" height="12" /> Back
                </div>
                <div onClick={selectAll} className="ml-auto cursor-pointer">Select all</div>
                <div onClick={onClear} className="cursor-pointer">Clear</div>
            </div>
            <div className="h-[300px] overflow-auto">
                {valueOptions.map((option) => {
                    const isSelected = selectedValues.includes(option);
                    return (
                        <div
                            key={option}
                            className={`flex cursor-pointer items-center justify-between p-2 hover:bg-slate-100 w-full ${isSelected && 'bg-slate-100'}`}
                            onClick={() => onClick(option, isSelected)}>
                            <div className="flex items-center gap-2">
                                <CodeBracketIcon width="18" height="18" className="text-slate-400" />
                                <span>{option}</span>
                            </div>
                            {isSelected && <CheckIcon width="18" height="18" className="text-primary" />}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

const parseMetaValue = (value: string | null) => {
    return (value || '')
        .split(';')
        .filter((v) => v)
        .map((v) => {
            const [key, value] = v.split('=');
            return { key, value: value.split(',') };
        });
};

const stringifiedObjectsToKeyValuePair = (objects: string[]): string[] => {
    const pairs: string[] = [];
    for (const object of objects) {
        try {
            const entries = Object.entries(JSON.parse(object));
            for (const [key, value] of entries) {
                if (key && value) {
                    pairs.push([key, value].join('='));
                }
            }
        } catch (e) {
            console.error(e);
        }
    }
    return pairs;
};