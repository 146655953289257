import { Filter } from "src/features/models/discover/toolbar/filters/Types";
import { ISuperficialNode, ISuperficialSubnode } from "./INode";

export type OnOpenModelCode = () => void;
export type SetSelectedSubNode = (parentNode: ISuperficialNode, subNode: ISuperficialSubnode) => void;
export enum DiscoverView {
    Table = 'table',
    Graph = 'graph'
}
export type FilterMode = 'eql' | 'basic';
export type UpdateDataModelStateProps = {
    filters?: Filter[];
    eql?: string;
    columns?: string[];
    view?: DiscoverView;
}