import { ChangeEvent, useState } from "react";
import { DiscoverFilterProps } from "../Types";
import Input from "../../../../../../components/form/Input";

type FreeTextFilterProps = DiscoverFilterProps & {
    placeholder: string;
}

export const FreeTextFilter = ({ value, onChange, placeholder }: FreeTextFilterProps) => {
    const onInputChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value);
    const [focus] = useState(true);
    return (
        <div className="p-2">
            <Input background="bg-slate-100" height="h-8" placeholder={placeholder} value={value || ''} onInputChange={onInputChange} focus={focus} />
        </div>
    );
};