import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import getCriticalPath from '../../getCiriticalPath';
import { ISuperficialNode, NodeType, nodeHeight, nodeWidth } from '../../INode';
import { useSelector } from 'react-redux';
import { selectActiveAccountId } from '../../../../../infrastructure/state/slices/activeAccountSlice';
import { getNodeIdForDom } from '../../getNodeIdForDom';
import PageLoader from '../../../../../components/loaders/PageLoader';
import { ExpandedNode, IDAGNode, OnCollapse, SetShowNodeSidepane } from './types';
import { DataModelTooLargeScreen } from './DataModelTooLargeScreen';
import { DiscoverDAGCanvas } from './Canvas/DiscoverDAGCanvas';
import { selectIsMenuCollpased } from '../../../../../infrastructure/state/slices/isMenuCollpasedSlice';
import { getConnectedNodes, getDAGNodes } from './getDAGNodes';
import { MAX_RESOURCES_IN_DAG, MAX_RESOURCES_FOR_DAG_WITH_GOOD_PERFORMANCE, REASONABLE_MAX_RANKS_PER_SWIMLANE } from './DAGConfiguration';
import { customToast, notify } from '../../../../../components/Toaster';
import { NodePositionsMap } from '../../../../../infrastructure/DAG/Types';
import { generateOrphanNodes } from './generateOrphanNodes';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { events, trackEvent } from '../../../../../infrastructure/analytics';
import { WarningToast } from './WarningToast';
import { getHistoryStack } from 'src/infrastructure/state/slices/historyStackSlice';
import { Link } from 'src/components/Link';
import toast from 'react-hot-toast';
import { extractErrorMessage } from 'src/services/api';

const PERFORMANCE_WARNING = 'Performance may be decreased when exploring large data models.';

interface DiscoverNodesViewProps {
  setShowNodeSidepane: SetShowNodeSidepane;
  selectedNode: ISuperficialNode | null;
  setSelectedNode: (node: ISuperficialNode | null) => void;
  eql: string;
  expandedNodes: ExpandedNode[];
  setExpandedNodes: (expandedNodes: ExpandedNode[]) => void;
  setEqlErrorMessage: (message: string | null) => void;
  setIsLoadingResources: (isLoading: boolean) => void;
  isLoadingResources: boolean;
}

const getFullPath = (selectedNode: ISuperficialNode | null, nodes: ISuperficialNode[]) => {
  if (selectedNode) {
    return [
      ...getCriticalPath(nodes, selectedNode.id, 'right'),
      ...getCriticalPath(nodes, selectedNode.id, 'left'),
      selectedNode.id
    ];
  }
  return [];
};

export const DiscoverDAGView = ({
  setShowNodeSidepane,
  selectedNode,
  setSelectedNode,
  eql,
  expandedNodes,
  setExpandedNodes,
  setEqlErrorMessage,
  setIsLoadingResources,
  isLoadingResources
}: DiscoverNodesViewProps) => {
  const accountId = useSelector(selectActiveAccountId);
  const [nodes, setNodes] = useState<IDAGNode[]>([]);
  const isMenuCollapsed = useSelector(selectIsMenuCollpased);
  const [menuWidth, setMenuWidth] = useState<number>(0);
  const [dagTooLarge, setDagTooLarge] = useState<boolean>(false);
  const [nodesPositions, setNodesPositions] = useState<NodePositionsMap>(new Map<string, { x: number; y: number }>());
  const criticalPath = useMemo(() => getFullPath(selectedNode, nodes), [nodes, selectedNode]);
  const filterHashRef = useRef('');
  const expandedNodesHashRef = useRef('[]');
  const withFilters = useMemo(() => !!eql, [eql]);
  const [searchParams] = useSearchParams();
  const history = useSelector(getHistoryStack);
  const debouncedNodes = useRef(0);
  const debouncedNodePositions = useRef(0);
  const [isLoadingPositions, setIsLoadingPositions] = useState<boolean>(false);
  const navigate = useNavigate();

  const updateNodesPositions = useCallback((nodes: IDAGNode[]) => {
    setIsLoadingPositions(true);
    const timestamp = Date.now();
    debouncedNodePositions.current = timestamp;
    const newDAGWorker = new Worker(new URL('../../../../../infrastructure/DAG/worker', import.meta.url), {
      type: 'module',
    });
    newDAGWorker.onmessage = (event) => {
      if (timestamp !== debouncedNodePositions.current) {
        return;
      }
      setNodesPositions(event.data);
      newDAGWorker.terminate();
      setIsLoadingPositions(false);
    };
    newDAGWorker.postMessage({
      nodes: nodes.map((n) => ({ id: n.id, parents: n.parents, width: nodeWidth, height: nodeHeight, cluster: nodeTypeToClusterOrder.get(n.type) || 0 })),
      xOffset: -120,
      yOffset: 20,
      distanceBetweenForeignClusters: REASONABLE_MAX_RANKS_PER_SWIMLANE,
    });
  }, [setNodesPositions]);

  const updateFilteredNodes = useCallback((eql: string) => {
    const now = Date.now();
    debouncedNodes.current = now;
    setIsLoadingResources(true);
    setExpandedNodes([]);
    setSelectedNode(null);
    setTimeout(async () => {
      try {
        const { newNodes, filteredNodes } = await getDAGNodes({ accountId, eql });
        if (now === debouncedNodes.current) {
          const filteredWorkbookNodes = filteredNodes.filter(n => n.type === NodeType.TableauWorkbook);
          if (!selectedNode && filteredWorkbookNodes.length > 0 && history.length && history[history.length - 1].includes('view=table')) {
            customToast(<WarningToast toastId={'workbooksDiscoverDisplayed'} message={`${filteredWorkbookNodes.length} Tableau Workbooks are not displayed in the DAG view`} />, { duration: 8000, style: { maxWidth: 'fit-content' }, id: 'workbooksDiscoverDisplayed' });
            trackEvent(events.dataModelWorkbooksNotShownErrorDisplayed);
          }
          if (newNodes.length > MAX_RESOURCES_IN_DAG) {
            setDagTooLarge(true);
          }
          else {
            if (newNodes.length > MAX_RESOURCES_FOR_DAG_WITH_GOOD_PERFORMANCE) {
              customToast(t => <WarningToast toastId={t.id} message={PERFORMANCE_WARNING} />, { duration: 5000, style: { maxWidth: 'fit-content' } });
              trackEvent(events.dataModelPerformanceWarningDisplayed);
            }
            const orphanNodes = generateOrphanNodes(newNodes, expandedNodes, withFilters);
            newNodes.push(...orphanNodes);
            const distinctNodes = newNodes.filter((node, index, self) => self.findIndex(n => n.id === node.id) === index);
            updateNodesPositions(distinctNodes);
            setNodes(distinctNodes);
            setDagTooLarge(false);
          }
          setIsLoadingResources(false);
          setEqlErrorMessage(null);
        }
      } catch (e) {
        console.error(e);
        notify('Failed to fetch resources', 'error');
        setEqlErrorMessage(extractErrorMessage(e).message);
        setIsLoadingResources(false);
      }
    }, 1000);
  }, [setIsLoadingResources, setExpandedNodes, setSelectedNode, accountId, selectedNode, history, setEqlErrorMessage, expandedNodes, withFilters, updateNodesPositions]);

  const updateNodeConnections = useCallback(async (expandedNodes: ExpandedNode[]) => {
    setIsLoadingResources(true);
    const connectedNodes: IDAGNode[] = [];
    if (expandedNodes.length > 0) {
      const resourceConnectionsPerExpansion = await getConnectedNodes({ accountId, expandedNodes, maxResources: MAX_RESOURCES_IN_DAG - nodes.length });
      for (const resourceConnections of resourceConnectionsPerExpansion) {
        //Limit to direct connections if the total is too large with full depth
        if (resourceConnections.total + nodes.length > MAX_RESOURCES_IN_DAG && resourceConnections.depth === null) {
          customToast(t => {
            const goToTableView = () => {
              if (resourceConnections.direction === 'downstream') {
                navigate(`/data-model?view=table&Downstream dependents=${resourceConnections.originalUri}`);
              }
              else {
                navigate(`/data-model?view=table&Upstream dependencies=${resourceConnections.originalUri}`);
              }
              toast.remove(t.id);
            };
            return <WarningToast toastId={t.id} message={<>Too many connections to display. Showing only direct connections. <Link text="Click here" onClick={goToTableView} /> to view all {resourceConnections.direction} connections in tabular view.</>} />;
          }, { duration: 5000, style: { maxWidth: 'fit-content' } });
          setExpandedNodes(expandedNodes.map(e => e.nodeId === resourceConnections.originalUri ? { ...e, depth: 1 } : e));
        }
        else {
          connectedNodes.push(...resourceConnections.resources.map(r => ({ ...r, isConnectedNode: true })));
        }
      }
    }
    const newNodes = [...nodes.filter(n => !n.isConnectedNode), ...connectedNodes];
    const orphanNodes = generateOrphanNodes(newNodes, expandedNodes, withFilters);
    newNodes.push(...orphanNodes);
    const distinctNodes = newNodes.filter((node, index, self) => self.findIndex(n => n.id === node.id) === index);
    setNodes(distinctNodes);
    updateNodesPositions(distinctNodes);
    setIsLoadingResources(false);
  }, [nodes, accountId, setNodes, setIsLoadingResources, updateNodesPositions, withFilters, navigate, setExpandedNodes]);

  //On filters / node expansion change
  useEffect(() => {
    const filterHash = JSON.stringify({ eql });
    const expandedNodesHash = JSON.stringify(expandedNodes);
    const utlRaceCondition = (searchParams.get('highlightedNode') || searchParams.get('UTL')) && !eql;
    if (filterHash !== filterHashRef.current && !utlRaceCondition) {
      filterHashRef.current = filterHash;
      updateFilteredNodes(eql);
    }
    else if (expandedNodesHash !== expandedNodesHashRef.current) {
      expandedNodesHashRef.current = expandedNodesHash;
      updateNodeConnections(expandedNodes);
    }
  }, [eql, expandedNodes, updateFilteredNodes, updateNodeConnections, searchParams]);

  //Update canvas width when menu is collapsed
  useEffect(() => {
    const menuWidth = (document.querySelector('#menu-layout')?.getBoundingClientRect().width || 0) / window.innerWidth * 100;
    setMenuWidth(menuWidth);
  }, [isMenuCollapsed]);

  const onCollapse: OnCollapse = useCallback(({ node, direction, depth = null }) => {
    const newExpandedNodes = [...expandedNodes];
    const activeExpansion = newExpandedNodes.find((m) => m.nodeId === node.id && m.direction === direction);
    const isFullyExpanded = activeExpansion?.depth === null;
    const isDirectlyExpanded = activeExpansion && !isFullyExpanded;
    if (!activeExpansion) {
      newExpandedNodes.push({ nodeId: node.id, direction, depth });
    }
    else if (isDirectlyExpanded && depth === null) {
      activeExpansion.depth = depth;
    }
    else {
      newExpandedNodes.splice(newExpandedNodes.indexOf(activeExpansion), 1);
    }
    setExpandedNodes(newExpandedNodes);
    trackEvent(events.dagExpanded, { direction, node_type: node.type, depth });
  }, [expandedNodes, setExpandedNodes]);

  useEffect(() => {
    if (selectedNode) {
      scrollToNode(selectedNode.id);
    }
  }, [selectedNode, nodesPositions]);

  const filtersBarHeight =
    ((document.querySelector('#discoverToolbar')?.getBoundingClientRect().height || 0) / window.innerHeight) * 100;

  const onContainerClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (clickOutsideNode(e)) {
      setSelectedNode(null);
    }
  };

  if (isLoadingResources || isLoadingPositions) {
    return <PageLoader />;
  }

  if (dagTooLarge) {
    return <DataModelTooLargeScreen />;
  }

  return (
    <div className="relative bg-white" style={{ height: `${100 - filtersBarHeight}vh`, width: `${100 - menuWidth}vw` }} onClick={onContainerClick}>
      <DiscoverDAGCanvas
        onCollapse={onCollapse}
        criticalPath={criticalPath}
        nodes={nodes}
        setSelectedNode={setSelectedNode}
        setShowNodeSidepane={setShowNodeSidepane}
        selectedNode={selectedNode}
        nodesPositions={nodesPositions}
        withFilters={withFilters}
        expandedNodes={expandedNodes}
      />
    </div>
  );
};

const scrollToNode = (nodeId: string) => {
  const node = document.querySelector(`#${getNodeIdForDom(nodeId, 'dag')}`);
  if (node) {
    node.scrollIntoView({ block: 'center', inline: 'center' });
  }
};

const clickOutsideNode = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  let target: HTMLElement | null = e.target as HTMLElement;
  while (target) {
    if (target.id && target.id.includes('swimlane')) {
      return true;
    }
    target = target.parentElement;
  }
  return false;
};

const nodeTypeToClusterOrder = new Map<NodeType, number>([
  [NodeType.DataSource, 0],
  [NodeType.DataModel, 1],
  [NodeType.Metric, 1],
  [NodeType.Table, 1],
  [NodeType.GenericDataTransformation, 1],
  [NodeType.LookerView, 2],
  [NodeType.LookerSQLDerivedView, 2],
  [NodeType.LookerNativeDerivedView, 3],
  [NodeType.LookerExplore, 3],
  [NodeType.LookerLook, 4],
  [NodeType.LookerTile, 4],
  [NodeType.LookerDashboard, 5],
  [NodeType.TableauCustomQuery, 2],
  [NodeType.TableauEmbeddedDataSource, 2],
  [NodeType.TableauPublishedDataSource, 2],
  [NodeType.TableauView, 3],
  [NodeType.TableauDashboard, 4],
  [NodeType.TableauStory, 4],
]);
